import React, {useState} from 'react'
import {Button} from "primereact/button";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {InputText} from "primereact/inputtext";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import DHDateTimePicker from "../../../general/DHDateTimePicker";
import DHDefaultDrawer from "../../../general/DHDefaultDrawer";


export default function TournamentCopySidebar({tournamentId, turniersystem, propMannschaftHeim, propBezeichnung, propBezeichnungKurz, onCopy, onClose}) {

    const [newId, setNewId] = useState(undefined);
    const [beginn, setBeginn] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("[COPY] - " + propBezeichnung);
    const [bezeichnungKurz, setBezeichnungKurz] = useState(propBezeichnungKurz !== undefined && propBezeichnungKurz !== null && propBezeichnungKurz !== "" ? "[COPY] - " + propBezeichnungKurz : "");
    const [mannschaftHeim, setMannschaftHeim] = useState(propMannschaftHeim !== undefined && propMannschaftHeim !== null ? propMannschaftHeim : "");
    const [mannschaftGast, setMannschaftGast] = useState("");

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const copyTournament = () => {
        setErrorMessage(undefined);
        let tmpBezeichnung = bezeichnung;
        if( isLigaspieltag() ) {
            tmpBezeichnung = mannschaftHeim + " vs. " + mannschaftGast;
        }
        let data = {bezeichnung:tmpBezeichnung, bezeichnungShort: bezeichnungKurz, mannschaftHeim: mannschaftHeim, mannschaftGast: mannschaftGast, beginn: beginn};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/copy/" + tournamentId,
            data,
            json => {
                setInfoMessage("Turnier wurde erfolgreich kopiert. Du kannst direkt mit dem nächsten Kopieren weiter machen.");
                onCopy(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const isLigaspieltag = () => {
        return turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id;
    }

    const showSaveButton = () => {
        if( isLigaspieltag()) {
            return !mannschaftHeim || !mannschaftGast || !beginn;
        } else {
            return !bezeichnung || !beginn;
        }
    }

    return <DHDefaultDrawer onClose={() => onClose()} show={true} title="Event kopieren" children={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{padding:20}}>
            <div style={{marginBottom: 10}}>
                Das kopierte Event wird genau mit den gleichen Daten angelegt und kann danach
                geändert werden.
            </div>
            <div style={{marginBottom: 10}}>
                Bitte wähle hier die neue Bezeichnung und den Startzeitpunkt.
            </div>
            {isLigaspieltag() && <>
                <div style={{marginBottom: 10}}>
                    Name Heimmannschaft:<br/>
                    <InputText style={{width: "100%"}} value={mannschaftHeim}
                               onChange={(e) => setMannschaftHeim(e.target.value)}/>
                </div>
                <div style={{marginBottom: 10}}>
                    Name Gastmannschaft:<br/>
                    <InputText style={{width: "100%"}} value={mannschaftGast}
                               onChange={(e) => setMannschaftGast(e.target.value)}/>
                </div>
            </>}
            {!isLigaspieltag() && <>
                <div style={{marginBottom: 10}}>
                    Bezeichnung:<br/>
                    <InputText style={{width: "100%"}} value={bezeichnung}
                               onChange={(e) => setBezeichnung(e.target.value)}/>
                </div>
                <div style={{marginBottom: 10}}>
                    Bezeichnung(kurz):<br/>
                    <InputText style={{width: "100%"}} value={bezeichnungKurz} maxLength={20}
                               onChange={(e) => setBezeichnungKurz(e.target.value)}/>
                </div>
            </>
            }
            <div style={{marginBottom: 10}}>
                Startzeitpunkt<br/>
                <DHDateTimePicker disabled={false} value={beginn} changeValue={setBeginn}/>
            </div>
            <div style={{marginBottom: 10}}>
                Möchtest du das Event wirklich kopieren?
            </div>
            <div>
                <Button label="Speichern" icon="pi pi-check" disabled={showSaveButton()}
                        onClick={() => copyTournament()}/>
            </div>
        </div>
    </>}/>
}
