import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import ConfigUtil from "../../../../util/ConfigUtil";
import FetchUtil from "../../../../util/FetchUtil";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import DHSelectionComponent from "../../../general/DHSeclectionCompontent";

export default function BoardVergabeArea({eventOperatorId}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showProgessSpinner, setShowProgressSpinner] = useState(false);
    const [tournaments, setTournaments] = useState([]);
    const [boards, setBoards] = useState([]);
    const [boardsGruppeVergeben, setBoardsGruppeVergeben] = useState([]);
    const [boardsSchweizerPlatzierungVergeben, setBoardsSchweizerPlatzierungVergeben] = useState([]);
    const [boardsKoVergeben, setBoardsKoVergeben] = useState([]);
    const [boardsLuckyLoserVergeben, setBoardsLuckyLoserVergeben] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(undefined);
    const [selectedBoardId, setSelectedBoardId] = useState(undefined);

    const [tabValue, setTabValue] = React.useState("");

    useEffect(() => {
        if( selectedTournament === null || selectedTournament === undefined) {
            return;
        }
        if( showGruppe() ) {
            setTabValue("gruppe");
        } else if (showSchweizerSystem()) {
            setTabValue("gruppe");
        } else if (showKO()) {
            setTabValue("ko");
        } else if (showAnyGamesTournament() || showLigaspieltag()) {
            setTabValue("anygames");
        } else {
            setTabValue(undefined);
        }
    }, [selectedTournament]);

    useEffect(() => {
        if( eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        ladeBoards();

    }, [eventOperatorId]);

    useEffect(() => {
        if( eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        ladeTournaments();

    }, [eventOperatorId]);

    useEffect(() => {
        if( eventOperatorId === null || eventOperatorId === undefined) {
            return;
        }
        if( selectedTournament === null || selectedTournament === undefined) {
            return;
        }
        ladeDaten();
    }, [eventOperatorId, selectedTournament]);

    const ladeTournaments = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/tournaments/started/"+ eventOperatorId,
            json => {
                setTournaments(json);
                if( json.length === 1) {
                    setSelectedTournament(json[0]);
                }
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Turniere: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Turniere: " + error.message);
            }
        );
    }

    const ladeBoards = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/"+ eventOperatorId,
            dto => {
                setBoards(dto.boards);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Boards: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Boards: " + error.message);
            }
        );
    }

    const ladeDaten = () => {
        if( !selectedTournament) {
            return;
        }
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/boardsByTournament/"+ eventOperatorId +"/" + selectedTournament.id,
            dto => {
                let tournamentBoards = dto.tournamentBoards;
                let tmpKO = [];
                let tmpLuckyLoser = [];
                let tmpschweizerplatzierung = [];
                let tmpGruppe = [];
                for (const board of tournamentBoards) {
                    if( board.type === "gruppe") {
                        tmpGruppe.push({gruppe: board.gruppe, boardId: board.boardId});
                    } else if( board.type === "schweizerplatzierung") {
                        tmpschweizerplatzierung.push({boardId: board.boardId});
                    } else if( board.type === "ko" && board.koArea === null) {
                        tmpKO.push({boardId: board.boardId});
                    }  else if( board.type === "luckyloser" && board.koArea === null) {
                        tmpLuckyLoser.push({boardId: board.boardId});
                    }
                }
                setBoardsSchweizerPlatzierungVergeben(tmpschweizerplatzierung);
                setBoardsGruppeVergeben(tmpGruppe);
                setBoardsKoVergeben(tmpKO);
                setBoardsLuckyLoserVergeben(tmpLuckyLoser);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const save = () => {
        setShowProgressSpinner(true);
        let dtos = [];
        for (const boardElement of boardsSchweizerPlatzierungVergeben) {
            dtos.push({boardId: boardElement.boardId, type: "schweizerplatzierung"});
        }
        for (const boardElement of boardsKoVergeben) {
            dtos.push({boardId: boardElement.boardId, type: "ko"});
        }
        for (const boardElement of boardsLuckyLoserVergeben) {
            dtos.push({boardId: boardElement.boardId, type:"luckyloser"});
        }
        for (const boardElement of boardsGruppeVergeben) {
            dtos.push({gruppe: boardElement.gruppe, boardId: boardElement.boardId, type:"gruppe"});
        }

        let data = {tournamentBoards: dtos};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/saveBoardsByTournament/" + eventOperatorId  + "/" + selectedTournament.id,
            data,
            json => {
                setInfoMessage("Daten wurden gespeichert!");
                ladeDaten();
                setShowProgressSpinner(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Speichern: " + responseNotOk.message);
                setShowProgressSpinner(false);
            },
            error => {
                setErrorMessage("Fehler beim Speichern: " + error.message);
                setShowProgressSpinner(false);
            })
    }

    const isBoardInKo = () => {
        return boardsKoVergeben.filter(tmpBoard => tmpBoard.boardId === selectedBoardId).length === 1;
    }

    const isBoardInLuckyLoser = () => {
        return boardsLuckyLoserVergeben.filter(tmpBoard => tmpBoard.boardId === selectedBoardId).length === 1;
    }

    const isBoardInSchweizerPlatzierung = () => {
        return boardsSchweizerPlatzierungVergeben.filter(tmpBoard => tmpBoard.boardId === selectedBoardId).length === 1;
    }

    const getBoardComponent = () => {
        const tmp = [];
        tmp.push({name: 'Auswahl löschen', code: undefined});
        for (const b of boards) {
            if( b.eventOperatorIdShared )  {
                tmp.push({name: <div>
                        <div>{b.bezeichnung}</div>
                        <div style={{fontSize:12}}>
                            {b.beschreibungShared}
                        </div>
                    </div>, code: b.id});
            } else {
                tmp.push({name: b.bezeichnung, code: b.id});
            }
        }
        return <div key={"key_dh_component"}>
            <DHSelectionComponent title="Auswahl" disabled={false} onChange={(id) => setSelectedBoardId(id)} value={selectedBoardId} values={tmp}/>
        </div>
    }

    const addSchweizerPlatzierungBoard = () => {
        let tmpBoardsVergeben = [...boardsSchweizerPlatzierungVergeben];
        tmpBoardsVergeben.push({boardId: selectedBoardId});
        setBoardsSchweizerPlatzierungVergeben(tmpBoardsVergeben);
        setSelectedBoardId(undefined);
    }

    const addKoBoard = () => {
        let tmpBoardsVergeben = [...boardsKoVergeben];
        tmpBoardsVergeben.push({boardId: selectedBoardId});
        setBoardsKoVergeben(tmpBoardsVergeben);
        setSelectedBoardId(undefined);
    }

    const addKoAllBoards = () => {
        let tmpBoardsVergeben = [...boardsKoVergeben];
        for (const board of boards) {
            // prüfen ob schon vorhanden
            if( tmpBoardsVergeben.filter(value => value.boardId === board.id).length === 0) {
                tmpBoardsVergeben.push({boardId: board.id});
            }
        }
        setBoardsKoVergeben(tmpBoardsVergeben);
        setSelectedBoardId(undefined);
    }

    const addKoAllSchweizerPlatzierung = () => {
        let tmpBoardsVergeben = [...boardsSchweizerPlatzierungVergeben];
        for (const board of boards) {
            // prüfen ob schon vorhanden
            if( tmpBoardsVergeben.filter(value => value.boardId === board.id).length === 0) {
                tmpBoardsVergeben.push({boardId: board.id});
            }
        }
        setBoardsSchweizerPlatzierungVergeben(tmpBoardsVergeben);
        setSelectedBoardId(undefined);
    }

    const addLuckyLoserBoard = () => {
        let tmpBoardsVergeben = [...boardsLuckyLoserVergeben];
        tmpBoardsVergeben.push({boardId: selectedBoardId});
        setBoardsLuckyLoserVergeben(tmpBoardsVergeben);
        setSelectedBoardId(undefined);
    }

    const addLuckyLoserAllBoards = () => {
        let tmpBoardsVergeben = [...boardsLuckyLoserVergeben];
        for (const board of boards) {
            // prüfen ob schon vorhanden
            if( tmpBoardsVergeben.filter(value => value.boardId === board.id).length === 0) {
                tmpBoardsVergeben.push({boardId: board.id});
            }
        }
        setBoardsLuckyLoserVergeben(tmpBoardsVergeben);
        setSelectedBoardId(undefined);
    }

    const removeKoBoard = (boardId) => {
        let tmpBoardsVergeben = [...boardsKoVergeben];
        tmpBoardsVergeben = tmpBoardsVergeben.filter(value => value.boardId !== boardId);
        setBoardsKoVergeben(tmpBoardsVergeben);
    }

    const removeSchweizerPlatzierungBoard = (boardId) => {
        let tmpBoardsVergeben = [...boardsSchweizerPlatzierungVergeben];
        tmpBoardsVergeben = tmpBoardsVergeben.filter(value => value.boardId !== boardId);
        setBoardsSchweizerPlatzierungVergeben(tmpBoardsVergeben);
    }

    const removeLuckyLoserBoard = (boardId) => {
        let tmpBoardsVergeben = [...boardsLuckyLoserVergeben];
        tmpBoardsVergeben = tmpBoardsVergeben.filter(value => value.boardId !== boardId);
        setBoardsLuckyLoserVergeben(tmpBoardsVergeben);
    }

    const isBoardInGruppe = (gruppennummer) => {
        return boardsGruppeVergeben.filter(tmpBoard => tmpBoard.boardId === selectedBoardId && tmpBoard.gruppe === gruppennummer).length === 1;
    }

    const addGruppeBoard = (gruppennummer) => {
        let boardBereitsInDerGruppe = isBoardInGruppe(gruppennummer);
        if( boardBereitsInDerGruppe) {
            setInfoMessage("Board ist bereits dieser Gruppe zugewiesen.");
            return;
        }

        let tmpBoardsVergeben = [...boardsGruppeVergeben];
        tmpBoardsVergeben.push({gruppe: gruppennummer, boardId: selectedBoardId});
        setBoardsGruppeVergeben(tmpBoardsVergeben);
    }

    const addGruppeBoards = (gruppennummer) => {
        let tmpBoardsVergeben = [...boardsGruppeVergeben];
        for (const board of boards) {
            // prüfen ob schon vorhanden
            if( tmpBoardsVergeben.filter(value => value.gruppe === gruppennummer &&  value.boardId === board.id).length === 0) {
                tmpBoardsVergeben.push({gruppe: gruppennummer, boardId: board.id});
            }
        }
        setBoardsGruppeVergeben(tmpBoardsVergeben);
        setSelectedBoardId(undefined);
    }

    const removeGruppeBoard = (boardId, gruppe) => {
        let tmpBoardsVergeben = [];
        for (const element of boardsGruppeVergeben) {
            if( !(element.boardId === boardId && element.gruppe === gruppe)) {
                tmpBoardsVergeben.push(element);
            }
        }
        setBoardsGruppeVergeben(tmpBoardsVergeben);
    }

    const getVergebeneBoards = (tmpBoards, remove) => {
        let rows = [];
        for (const board of tmpBoards) {
            let tmpArray = boards.filter(value => value.id === board.boardId);
            if (tmpArray !== null && tmpArray !== undefined && tmpArray.length === 1) {
                let tmpBoard = tmpArray[0];
                rows.push(<div key={"key_ko_board_" + board.boardId} style={{marginTop: 10, fontSize: 16}}>
                    <Button icon="pi pi-minus-circle" style={{marginRight: 10, height: 30, width: 30}} onClick={() => remove(board.boardId)}/>
                    {tmpBoard.bezeichnung}
                    {tmpBoard.beschreibungShared && <><br/>{tmpBoard.beschreibungShared}</>}
                </div>)
            }
        }
        return <div>
            {rows}
        </div>
    }

    const getGruppeVergebeneBoards = (gruppennummer) => {
        let rows = [];
        for (const board of boardsGruppeVergeben) {
            if( board.gruppe === gruppennummer) {
                let existiertBoardInAndererGruppe = boardsGruppeVergeben.filter(tmpBoard => tmpBoard.boardId === board.boardId && tmpBoard.gruppe !== gruppennummer).length > 0;
                let tmpArray = boards.filter(value => value.id === board.boardId);
                if( tmpArray !== null && tmpArray !== undefined && tmpArray.length === 1) {
                    let tmpBoard = tmpArray[0];
                    rows.push(<div key={"key_gruppe_" + gruppennummer +"_board_" + board.boardId} style={{marginTop:10, fontSize:16}}>
                        <Button icon="pi pi-minus-circle" style={{marginRight:10, height:30, width:30}} onClick={() => removeGruppeBoard(board.boardId, board.gruppe)}/>
                        {tmpBoard.bezeichnung}
                        {existiertBoardInAndererGruppe && <span style={{marginLeft:10,fontSize:12, color:"red"}}>Board in mehreren Gruppen!!!</span>}</div>)
                }
            }
        }
        return <div>
            {rows}
        </div>
    }

    const getGruppenRow = (gruppennummer) => {
        return <div key={"key_gruppe_" + gruppennummer} style={{maxWidth:300, padding:5, border: "1px solid #DDD", margin:5}}>
            <div>
                <div style={{fontWeight:"bold", marginBottom:10}}>
                    Boards für Gruppe {gruppennummer}:
                </div>
                <div><Button label="Alle Boards dieser Gruppe zuweisen" style={{height:30, width:"100%"}} onClick={() => addGruppeBoards(gruppennummer)}/></div>
                <div style={{display:"flex", margin:"auto", verticalAlign:"middle", alignItems:"center", marginTop:5}}>
                    <Button icon="pi pi-plus-circle" style={{height:30, width:"100%"}}
                            disabled={selectedBoardId === undefined || isBoardInGruppe(gruppennummer)}
                            onClick={() => addGruppeBoard(gruppennummer)}/>
                </div>
            </div>
            {getGruppeVergebeneBoards(gruppennummer)}
        </div>
    }


    const getTabs = () => {
        let returnValue = [];
        {showGruppe() && returnValue.push(erzeugeTab("Gruppenphase" ,"gruppe", false))}
        {showAnyGamesTournament() && showLigaspieltag() && returnValue.push(erzeugeTab("Boards" ,"anygames", false))}
        {showSchweizerSystem() && returnValue.push(erzeugeTab("Gruppen" ,"schweizersystem", false))}
        {showKO() && returnValue.push(erzeugeTab("KO/DKO Phase" ,"ko", false))}
        {showSchweizerSystem() && returnValue.push(erzeugeTab("Finale Platzierung" ,"finaleplatzierung", false))}
        {showLuckyLoser() && returnValue.push(erzeugeTab("Lucky Loser Turnier" ,"luckyloser", false))}
        return returnValue;
    }

    const showGruppe = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id) {
            return true;
        }
        return false;
    }

    const showKO = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id ) {
            return true;
        }
        return false;
    }

    const showLigaspieltag = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id ) {
            return true;
        }
        return false;
    }

    const showSchweizerSystem = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id ) {
            return true;
        }
        return false;
    }

    const showAnyGamesTournament = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.ANY_GAMES.id ) {
            return true;
        }
        return false;
    }

    const showLuckyLoser = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id) {
            return true;
        }
        return false;
    }

    const nichtUnterstuetztesTurniersystem = () => {
        if (selectedTournament === undefined) {
            return false;
        }

        if (selectedTournament.turniersystem === TOURNAMENTSYSTEMS.LIGA.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id ||
            selectedTournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id) {
            return true;
        }
        return false;
    }

    const erzeugeTab = (value, key, icon) => {
        return <div style={{fontSize: 16, paddingLeft:10, paddingRight:10, cursor:"pointer", borderBottom: tabValue === key ? "2px solid red" : "none", textAlign:"center"}}
                    onClick={() => setTabValue(key)}>
            {value.toUpperCase()}
        </div>
    }

    const getTabpanel = () => {
        if (tabValue === "gruppe" || tabValue === "schweizersystem") {
            if( selectedTournament.anzahlGruppen === 0 ) {
                return <div>Es sind noch keine Gruppen im Turnier vorhanden.<br/><br/>Du musst das Turnier starten, danach kannst du die Einstellungen hier vornehmen.</div>
            } else {
                return <div>
                    <h3>Bitte Einteilung der Boards für die Gruppenphase vornehmen: </h3>
                    <div style={{padding: 10, marginBottom: 10}}>
                        <span style={{fontWeight: "bold"}}>Hinweis:</span> Solltest du mehr Boards als Gruppen zur Verfügung haben, ist die beste Einstellung jeweils 1 festes Board pro Gruppe zu vergeben
                        und die restlichen Boards, sofern gewünscht, auf alle Boards zu vergeben.<br/>
                        Mit dieser Einstellung wird in jeder Gruppe gespielt und zusätzlich versucht Darthelfer die Gruppen mit den restlichen Boards gleichmäßig zu Ende zu spielen.
                    </div>
                    <div style={{padding: 10, marginBottom:10}}><span style={{fontWeight:"bold"}}>Boardauswahl:</span><br/>{getBoardComponent()}</div>
                    <div className="grid" style={{padding: 5, marginLeft: 0, marginRight: 0}}>
                        {[...Array(selectedTournament.anzahlGruppen)].map((x, i) =>
                            getGruppenRow(i + 1)
                        )}
                    </div>
                </div>
            }
        } else if (tabValue === "anygames") {
            return <div style={{padding: 10}}>
                <h3>Bitte Boards auswählen.</h3>
                <div><Button label="Alle Boards zuweisen" style={{height: 30, width: 300}} onClick={() => addKoAllBoards()}/></div>
                <div style={{marginBottom: 10, marginTop: 10}}><span style={{fontWeight: "bold"}}>Boardauswahl:</span><br/>{getBoardComponent()}</div>
                <div style={{marginTop: 20}}>
                    <Button icon="pi pi-plus-circle" style={{height: 30, width: 300}} disabled={selectedBoardId === undefined || isBoardInKo()}
                            onClick={() => addKoBoard()}/>
                </div>
                {getVergebeneBoards(boardsKoVergeben, removeKoBoard)}
            </div>
        } else if (tabValue === "finaleplatzierung") {
            return <div style={{padding: 10}}>
                <h3>Bitte Boards für die finalen Platzierungsspiele auswählen.</h3>
                <h3>Diese Auswahl muss nur getätigt werden, sobald du mehrere Gruppen im Schweizer System spielst und nach dieser Gruppenphase Entscheidungsspiele zwischen den Platzierten spielen
                    willst.</h3>
                <div><Button label="Alle Boards zuweisen" style={{height: 30}} onClick={() => addKoAllSchweizerPlatzierung()}/></div>
                <div style={{marginBottom: 10, marginTop: 10}}><span style={{fontWeight: "bold"}}>Boardauswahl:</span><br/>{getBoardComponent()}</div>
                <div style={{marginTop: 20}}>
                    <Button icon="pi pi-plus-circle" style={{marginLeft: 10, height: 30, width: 30}} disabled={selectedBoardId === undefined || isBoardInSchweizerPlatzierung()}
                            onClick={() => addSchweizerPlatzierungBoard()}/>
                </div>
                {getVergebeneBoards(boardsSchweizerPlatzierungVergeben, removeSchweizerPlatzierungBoard)}
            </div>
        } else if (tabValue === "ko") {
            return <div style={{padding: 10}}>
                <h3>Bitte Boards für die KO/DKO-phase auswählen.</h3>
                <div><Button label="Alle Boards der KO/DKO Phase zuweisen" style={{height: 30, width:300}} onClick={() => addKoAllBoards()}/></div>
                <div style={{marginBottom: 10, marginTop:10}}><span style={{fontWeight: "bold"}}>Boardauswahl:</span><br/>{getBoardComponent()}</div>
                <div style={{marginBottom: 20}}>
                    <Button icon="pi pi-plus-circle" style={{height: 30, width: 300}} disabled={selectedBoardId === undefined || isBoardInKo()} onClick={() => addKoBoard()}/>
                </div>
                {getVergebeneBoards(boardsKoVergeben, removeKoBoard)}
            </div>
        } else if (tabValue === "luckyloser") {
            return <div style={{padding: 10}}>
                <h3>Bitte Boards für ein eventuelles Lucky Loser Turnier auswählen.</h3>
                <div><Button label="Alle Boards dem Lucky Loser Turnier zuweisen" style={{height: 30}} onClick={() => addLuckyLoserAllBoards()}/></div>
                <div style={{marginBottom: 10, marginTop: 10}}><span style={{fontWeight: "bold"}}>Boardauswahl:</span><br/>{getBoardComponent()}</div>
                <div style={{marginBottom: 20}}>
                    <Button icon="pi pi-plus-circle" style={{height: 30, width: 300}} disabled={selectedBoardId === undefined || isBoardInLuckyLoser()} onClick={() => addLuckyLoserBoard()}/>
                </div>
                {getVergebeneBoards(boardsLuckyLoserVergeben, removeLuckyLoserBoard)}
            </div>
        }
        return "";
    }

    const getTournamentSelection = () => {
        const tmp = [];
        tmp.push({name: 'Auswahl löschen', code: undefined});
        for (const t of tournaments) {
            tmp.push({name: t.bezeichnung, code: t.id});
        }
        return <div>
            <span style={{marginRight: 10}}>Turnierauswahl:</span>
            <DHSelectionComponent title="Auswahl" disabled={false} value={selectedTournament ? selectedTournament.id : undefined} values={tmp}
                                  onChange={(id) => {
                                      if( id === undefined) {
                                          setSelectedTournament(undefined);
                                      } else {
                                          let tournament = tournaments.filter(t => t.id === id)[0];
                                          setSelectedTournament(tournament);
                                      }
                                  }}/>
        </div>
    }


    return <div style={{padding: 20}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)}/>
        {getTournamentSelection()}

        {selectedTournament !== undefined && <>
            <div style={{fontWeight: "bold", color: "red", marginTop: 20, marginBottom: 20}}>Solltest du Schreiber an deinem Turnier wünschen, nimm vor der Boardvergabe die Schreibereinstellung vor. Sonst würden evtl. Spiele ohne Schreiber angelegt.</div>
            {nichtUnterstuetztesTurniersystem() &&
                <div style={{marginBottom: 20, fontSize:18, color:"red"}}>
                    Das Turniersytem, z.B. Liga, Kratzer usw., wird bei der automatischen Turniervergabe hier nicht unterstützt. Evtl. Einstellung direkt im Ligaspieltag o.ä. vornehmen.
                </div>
            }
            <div style={{marginBottom:20}}>
                <div style={{display:"flex"}}>
                    {getTabs()}
                </div>
            </div>
            {getTabpanel()}
            <div style={{marginTop:10}}>
                {showProgessSpinner ?
                    <ProgressSpinner style={{width: 50, height: 50}} />
                    :
                    <Button label="Speichern" icon="pi pi-save" onClick={() => save()} disabled={selectedTournament === undefined || nichtUnterstuetztesTurniersystem()}/>
                }
            </div>
        </>}
    </div>
}
