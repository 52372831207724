import React, {useState} from "react";
import {Button} from "primereact/button";
import {Accordion, AccordionTab} from "primereact/accordion";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import TournamentKratzerNaechsteRundeAuslosungDialog from "./auslosung/TournamentKratzerNaechsteRundeAuslosungDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import PlayerUtil from "../../../util/PlayerUtil";

export default function TournamentKratzerGames({tournament, readOnly, aktuelleRunde, gewinnerPlayerId, tournamentPlayers, tournamentKratzerGames, tournamentKratzerGamePlayers, restpunkteDtos, onCallbackReload}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [showAuslosungNaechsteRundeDialog, setShowAuslosungNaechsteRundeDialog] = useState(false);

    const getAnzahlSpielerMitRestpunkten = () => {
        return restpunkteDtos.filter(dto => dto.restpunkte > 0).length
    }

    const getSpielerName = (id) => {
        return PlayerUtil.getSpielerName(tournamentPlayers, id);
    }

    const isTurnierAbgeschlossen = () => {
        return tournament.tournamentstatus === "abgeschlossen";
    }

    const auslosungNaechsteRunde = (anzahlGames) => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/kratzer/auslosen/" + tournament.id + "/" + anzahlGames,
            {},
            json => {
                setInfoMessage("Auslosung nächste Runde wurde durchgeführt.");
                setShowAuslosungNaechsteRundeDialog(false);
                onCallbackReload();
            },
            responseNotOk => {
                setErrorMessage("Fehler bei Auslosung: " + responseNotOk.message);
                setShowAuslosungNaechsteRundeDialog(false);
            },
            error => {
                setErrorMessage("Fehler bei Auslosung: " + error.message);
                setShowAuslosungNaechsteRundeDialog(false);
            }
        );
    }

    const changeVerloren = (kratzerGamePlayerId) => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/kratzer/player/verloren/" + kratzerGamePlayerId,
            {},
            json => {
                setInfoMessage("Player - verloren - wurde geändert.");
                onCallbackReload();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Player verloren: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Player verloren: " + error.message);
            }
        );
    }

    const getSpielrunden = () => {
        let tabs = [];
        for (let i = aktuelleRunde; i > 0 ; i--) {
            let games = [];
            let filteredGames = tournamentKratzerGames.filter(game => game.runde === i);
            for (const game of filteredGames) {
                let filteredGamePlayers = tournamentKratzerGamePlayers.filter(player => player.kratzerGameId === game.id);
                let players = [];
                for (const player of filteredGamePlayers) {
                    let restpunkte = undefined;
                    let darfButtonBetaetigtWerden = false;
                    if( i === aktuelleRunde) {
                        let dto = restpunkteDtos.filter(dto => dto.tournamentPlayerId === player.tournamentPlayerId)[0];
                        restpunkte = " (Kratzer: " + dto.restpunkte + ")";
                        darfButtonBetaetigtWerden = !readOnly && !isTurnierAbgeschlossen();
                    }

                    players.push(<div key={"key_game_" + game.id + "_player_" + player.id } style={{display:"flex", height:50, padding:10, verticalAlign:"middle", alignItems:"center", margin:"auto"}}>
                        <Button icon="pi pi-thumbs-down" style={{marginRight:10, backgroundColor: player.verloren ? "#e51853" : ""}}
                                onClick={() => changeVerloren(player.id)}
                                disabled={!darfButtonBetaetigtWerden}/>
                        {getSpielerName(player.tournamentPlayerId)}
                        {restpunkte !== undefined && restpunkte}
                    </div>)
                }

                games.push(<div key={"key_game_" + game.id} style={{width:350, backgroundColor:"white", color:"black", textAlign:"left", marginRight:20, marginTop:10, display:true, boxShadow:"0 2px 15px #888888"}}>
                    <div style={{padding:10, backgroundColor:"#e51853", color:"white", fontSize:20}}>
                        Spielnummer: {game.spielnummer}
                    </div>
                    {players}
                </div>)
            }
            tabs.push(<AccordionTab header={"Spielrunde: " + i} key={"key_tab_" + i}>
                    <div className="grid">{games}</div>
                </AccordionTab>
            );
        }
        return tabs;
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {!readOnly && !isTurnierAbgeschlossen() && (gewinnerPlayerId === undefined || gewinnerPlayerId === null) &&
            <div style={{marginBottom:20, textAlign:"left"}}>
                <Button label="Auslosung nächste Runde starten" icon="pi pi-key"
                        onClick={() => setShowAuslosungNaechsteRundeDialog(true)}
                        style={{height: 40, width: 300, marginLeft: 10, marginRight: 10}}/>
            </div>
        }
        {gewinnerPlayerId !== null && gewinnerPlayerId !== undefined &&
            <div style={{color:"green", fontWeight:"bold", fontSize:25, textAlign:"left", marginBottom:10}}>
                Gewinner: {getSpielerName(gewinnerPlayerId)}
            </div>
        }
        <Accordion activeIndex={0}>
            {getSpielrunden()}
        </Accordion>
        {showAuslosungNaechsteRundeDialog &&
            <TournamentKratzerNaechsteRundeAuslosungDialog callBackOnNein={() => setShowAuslosungNaechsteRundeDialog(false)}
                                                           anzahlSpieler={getAnzahlSpielerMitRestpunkten()}
                                                           callBackOnAuslosen={auslosungNaechsteRunde}/>}

    </div>

}
