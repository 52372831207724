import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Button} from "primereact/button";
import ConfirmDialog from "../../../general/ConfirmDialog";
import TournamentAuslosungDialog from "../auslosung/TournamentAuslosungDialog";
import TurnierChangeInactivePlayerDialog from "../TurnierChangeInactivePlayerDialog";
import TurnierAddPlayerDialog from "../TurnierAddPlayerDialog";
import TurnierAddDoppelPlayerDialog from "../TurnierAddDoppelPlayerDialog";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import TurnierSetzlisteKoDialog from "../TurnierSetzlisteKoDialog";
import TurnierPlayerDialog from "../TurnierPlayerDialog";
import ConfirmWithQuestionDialog from "../../../general/ConfirmWithQuestionDialog";
import {PrimeIcons} from "primereact/api";
import TournamentCopySidebar from "../../public/tournaments/TournamentCopySidebar";
import ExportPlayersButton from "../ExportPlayersButton";
import TurnierSetzlisteGruppeDialog from "../TurnierSetzlisteGruppeDialog";
import ChangeModusGroupDialog from "../ChangeModusGroupDialog";
import EventleiterVerwaltenDialog from "../../liga/EventleiterVerwaltenDialog";
import {ProgressSpinner} from "primereact/progressspinner";
import DHDefaultDrawer from "../../../general/DHDefaultDrawer";

function LigaAktionenComponent({showAsSidebar, proTournament, tournamentId, userId, onError, onInfo, onClearMessages, callBackCloseDialog, callBackRefresh, callBackLoadNewTournament, callBackOnDelete, onClose}) {

    let [showEventZuruecksetzen, setShowEventZuruecksetzen] = useState(false);
    let [showConfirmDialogTurnierLoeschen, setShowConfirmDialogTurnierLoeschen] = useState(false);
    let [showAuslosungDialog, setShowAuslosungDialog] = useState(false);
    let [showAbschliessenDialog, setShowAbschliessenDialog] = useState(false);
    let [showWiederEroeffnenDialog, setShowWiederEroeffnenDialog] = useState(false);
    let [showChangeInactivePlayerDialog, setShowChangeInactivePlayerDialog] = useState(false);
    let [showAddPlayerDialog, setShowAddPlayerDialog] = useState(false);
    let [showAddDoppelPlayerDialog, setShowAddDoppelPlayerDialog] = useState(false);
    let [showSetzlisteKoDialog, setShowSetzlisteKoDialog] = useState(false);
    let [showSetzlisteGruppeDialog, setShowSetzlisteGruppeDialog] = useState(false);
    let [showPlayerDialog, setShowPlayerDialog] = useState(false);
    let [showCopyDialog, setShowCopyDialog] = useState(false);
    let [showRanglisteDialog, setShowRanglisteDialog] = useState(false);
    let [showChangeModusGroup, setShowChangeModusGroup] = useState(false);
    let [showAdministratoren, setShowAdministratoren] = useState(false);

    let [tournament, setTournament] = useState(undefined);

    useEffect(() => {
        if( proTournament === null || proTournament === undefined) {
            loadTournament();
        } else {
            setTournament(proTournament);
        }
    }, [proTournament]);

    const loadTournament = () => {
        if( tournamentId === null || tournamentId === undefined) {
            return;
        }
        if( userId === null || userId === undefined) {
            onError("Admin verständigen. UserId not found.");
            onClose();
            return;
        }

        // Die Infos die auf diesen Turnier gebraucht werden, können über ladekodko ausreichend besorgt werden.
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/ladekodko/" + tournamentId,
            json => {
                let tournament = json.tournamentDto;
                let adminFound = false;
                let admins = tournament.administratoren;
                for (const admin of admins) {
                    if( admin.userId === userId) {
                        adminFound = true;
                    }
                }
                if( !adminFound ) {
                    onError("Du bist kein Admin für dieses Event.");
                    onClose();
                } else {
                    setTournament(tournament);
                }
            },
            responseNotOk => {
                onError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                onError("Fehler beim Laden: " + error.message);
            }
        );
    }

    function turnierWiederOeffen () {
        onClearMessages();
        let data = {tournamentid: tournament.id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/settournamennichtabgeschlossen",
            data,
            json => {
                setShowWiederEroeffnenDialog(false);
                onInfo("Turnier wurde wieder eröffnet.");
                refresh();
            },
            responseNotOk => {
                onError("Fehler bei der Statusänderung eines Turniers: " + responseNotOk.message);
                setShowWiederEroeffnenDialog(false);
            },
            error => {
                onError("Fehler bei der Statusänderung eines Turniers: " + error.message);
                setShowWiederEroeffnenDialog(false);
            }
        );
    }

    function turnierAbschliessen () {
        onClearMessages();
        let data = {tournamentid: tournament.id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/settournamentabgeschlossen",
            data,
            json => {
                setShowAbschliessenDialog(false);
                onInfo("Turnier wurde erfolgreich geschlossen.");
                refresh();
                callBackCloseDialog();
            },
            responseNotOk => {
                onError("Fehler beim Abschliessen eines Turniers: " + responseNotOk.message);
                setShowAbschliessenDialog(false);
            },
            error => {
                onError("Fehler beim Abschliessen eines Turniers: " + error.message);
                setShowAbschliessenDialog(false);
            }
        );
    }

    function tournamentZuruecksetzen() {
        onClearMessages();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/zuruecksetzen/" + tournament.id,
            {},
            json => {
                setShowEventZuruecksetzen(false);
                callBackOnDelete();
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message);
                setShowEventZuruecksetzen(false);
            },
            error => {
                onError("Fehler: " + error.message);
                setShowEventZuruecksetzen(false);
            }
        );

    }

    function deleteTournament() {
        onClearMessages();
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.tournament + "/" + tournament.id,
            {},
            json => {
                setShowConfirmDialogTurnierLoeschen(false);
                callBackOnDelete();
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message);
                setShowConfirmDialogTurnierLoeschen(false);
            },
            error => {
                onError("Fehler: " + error.message);
                setShowConfirmDialogTurnierLoeschen(false);
            }
        );
    }

    function ranglisteBerechnen() {
        onClearMessages();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.rangliste + "/berechnen/" + tournament.id,
            {},
            json => {
                onInfo("Rangliste wurde neu berechnet.")
                refresh();
                setShowRanglisteDialog(false);
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message);
                setShowRanglisteDialog(false);
            },
            error => {
                onError("Fehler: " + error.message);
                setShowRanglisteDialog(false);
            }
        );
    }

    const refresh = () => {
        loadTournament();
        callBackRefresh();
    }

    const getDataArea = () => {
        if( tournament === undefined) {
            return <div style={{marginTop:50}}>
                <ProgressSpinner style={{width: 50, height: 50}}/>
                Wir laden...
            </div>
        }
        let isLigaspieltag = tournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id
        let isLiga = tournament.turniersystem === TOURNAMENTSYSTEMS.LIGA.id
        let isKODKO = tournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id ||
            tournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id;
        let isTurnierMitGruppenphase = tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id;

        return <div style={{marginTop: 10, padding: 10}}>
            {showCopyDialog &&
                <TournamentCopySidebar
                    tournamentId={tournament.id}
                    turniersystem={tournament.turniersystem}
                    propMannschaftHeim={tournament.mannschaftHeim}
                    propBezeichnung={tournament.bezeichnung}
                    propBezeichnungKurz={tournament.bezeichnungShort}
                    onCopy={(newId) => callBackLoadNewTournament(newId)}
                    onClose={() => {
                        setShowCopyDialog(false);
                    }}
                />
            }
            {showPlayerDialog && <TurnierPlayerDialog tournament={tournament}
                                                      onRefresh={() => refresh()}
                                                      onClose={() => setShowPlayerDialog(false)}/>
            }
            {showSetzlisteKoDialog &&
                <TurnierSetzlisteKoDialog tournament={tournament}
                                          onRefresh={() => refresh()}
                                          onClose={() => setShowSetzlisteKoDialog(false)}/>
            }
            {showSetzlisteGruppeDialog &&
                <TurnierSetzlisteGruppeDialog tournament={tournament}
                                              onRefresh={() => refresh()}
                                              onClose={() => setShowSetzlisteGruppeDialog(false)}/>
            }
            {showAddDoppelPlayerDialog &&
                <TurnierAddDoppelPlayerDialog tournament={tournament}
                                              visible={true}
                                              callBackRefresh={() => refresh()}
                                              callBackDialogClose={() => setShowAddDoppelPlayerDialog(false)}/>
            }
            {showAddPlayerDialog &&
                <TurnierAddPlayerDialog tournament={tournament}
                                        visible={true}
                                        callBackRefresh={() => refresh()}
                                        callBackDialogClose={() => setShowAddPlayerDialog(false)}/>
            }
            {showChangeInactivePlayerDialog &&
                <TurnierChangeInactivePlayerDialog tournamentId={tournament.id}
                                                   isOnline={tournament.online}
                                                   onClose={() => setShowChangeInactivePlayerDialog(false)}
                                                   onRefresh={() => refresh()}/>
            }
            {showWiederEroeffnenDialog &&
                <ConfirmDialog visible={true}
                               callBackOnNein={() => setShowWiederEroeffnenDialog(false)}
                               callBackOnJa={() => turnierWiederOeffen()}
                               header="Turnier wirklich wiedereröffnen"
                               textOben="Sobald ein Event abgeschlossen war, kann es beim Wiedereröffnen zu großen Problemen kommen. Wir raten davon klar ab."
                               textUnten="Möchtest du das Event wirklich wiedereröffnen?"/>
            }
            {showAbschliessenDialog &&
                <ConfirmDialog visible={true}
                               callBackOnNein={() => setShowAbschliessenDialog(false)}
                               callBackOnJa={() => turnierAbschliessen()}
                               header="Turnier wirklich abschliessen"
                               textOben="Sobald ein Event abgeschlossen wird, kann diese Aktion nicht mehr rückgängig gemacht werden."
                               textUnten="Möchtest du das Event wirklich abschliessen?"/>
            }
            {showAuslosungDialog &&
                <TournamentAuslosungDialog onClose={() => setShowAuslosungDialog(false)}
                                                                     onRefresh={() => refresh()}
                                                                     tournament={tournament}/>
            }
            {showRanglisteDialog &&
                <ConfirmDialog visible={true}
                               callBackOnNein={() => setShowRanglisteDialog(false)}
                               callBackOnJa={() => ranglisteBerechnen()}
                               header="Rangliste neu berechnen"
                               textOben="ACHTUNG! Die Rangliste wird automatisch beim Turnier abschliessen erzeugt.
                               Die Gesamtrangliste wird immer LIVE aus den Ranglistenturnieren erzeugt.
                               Sofern du evtl. die Ranglistenpunkte o.ä. verändert hast, kannst du hier die Rangliste zu diesem Turnier/Liga neu berechnen lassen."
                               textUnten="Möchtest du die Rangliste neu berechnen?"/>
            }
            {showEventZuruecksetzen &&
                <>
                    <ConfirmWithQuestionDialog visible={true}
                                               callBackOnNein={() => setShowEventZuruecksetzen(false)}
                                               callBackOnJa={() => tournamentZuruecksetzen()}
                                               header="Event zurücksetzen"
                                               textOben="Du kannst hier das komplette Event zurücksetzen. Allgemeine Einstellungen wie Modus usw. bleiben erhalten, ebenfalls bleiben die Spieler erhalten. Danach kannst du z.B. Setzlisten usw. abändern und das Event erneut starten."
                                               textUnten="Möchtest du das Event wirklich zurücksetzen?"/>
                </>
            }
            {showConfirmDialogTurnierLoeschen &&
                <>
                    <ConfirmWithQuestionDialog visible={true}
                                               callBackOnNein={() => setShowConfirmDialogTurnierLoeschen(false)}
                                               callBackOnJa={() => deleteTournament()}
                                               header="Event wirklich löschen"
                                               textOben="Sobald du ein Event löscht, wird dieses wirklich unwiderruflich von der Datenbank entfernt. Teilnehmer von Onlineturnieren werden über das Löschen benachrichtigt. Die Daten können nicht mehr hergestellt werden."
                                               textUnten="Möchtest du das Event wirklich löschen?"/>
                </>
            }
            {showChangeModusGroup && <ChangeModusGroupDialog onClose={() => setShowChangeModusGroup(false)} tournamentId={tournament.id} bezeichnung={tournament.bezeichnung}/>}
            {showAdministratoren && <EventleiterVerwaltenDialog tournamentId={tournament.id} onRefresh={refresh}
                                                                onClose={() => setShowAdministratoren(false)}
                                                                visible={true}
                                                                admins={tournament.administratoren}/>
            }
            {tournament.tournamentstatus === "neu" && <>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-align-justify" label="Event starten"
                            onClick={() => setShowAuslosungDialog(true)}/>
                </div>
            </>
            }
            <div style={{marginTop: 20, marginBottom: 20}}>
                <Button icon="pi pi-trash" label="Event löschen"
                        onClick={() => setShowConfirmDialogTurnierLoeschen(true)}/>
            </div>
            {tournament.tournamentstatus === "gestartet" && !tournament.folgeTurnier && !tournament.luckyLoserTurnier &&
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <Button label="Event zurücksetzen"
                            onClick={() => setShowEventZuruecksetzen(true)}/>
                </div>
            }
            {tournament.tournamentstatus === "gestartet" && !tournament.doppelturnier && isLiga && <>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-user-plus" label="Spieler zur Liga hinzufügen"
                            onClick={() => setShowAddPlayerDialog(true)}/>
                </div>
            </>
            }
            {tournament.tournamentstatus === "gestartet" && tournament.doppelturnier && isLiga && <>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-user-plus" label="Doppelpaarung zu Liga hinzufügen"
                            onClick={() => setShowAddDoppelPlayerDialog(true)}/>
                </div>
            </>
            }
            {tournament.tournamentstatus === "gestartet" && (isLiga || isTurnierMitGruppenphase) && <>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-users" label="Inaktiven Teilnehmer tauschen"
                            onClick={() => setShowChangeInactivePlayerDialog(true)}/>
                </div>
            </>
            }
            {tournament.tournamentstatus === "gestartet" && !tournament.folgeTurnier && !tournament.luckyLoserTurnier && <>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-star" label="Event abschliessen, keine Änderung mehr möglich"
                            onClick={() => setShowAbschliessenDialog(true)}
                            className="p-button-danger"/>
                </div>
            </>
            }
            {tournament.tournamentstatus === "abgeschlossen" && !tournament.folgeTurnier && !tournament.luckyLoserTurnier && <>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-star" label="Event wieder aktivieren"
                            onClick={() => setShowWiederEroeffnenDialog(true)} className="p-button-success"/>
                </div>
            </>
            }
            {tournament.tournamentstatus === "abgeschlossen" && (tournament.rangliste || (tournament.ranglisteId !== null && tournament.ranglisteId !== undefined)) &&
                <div style={{marginTop: 10}}>
                    <Button icon={PrimeIcons.SLACK} label="Rangliste neu berechnen"
                            onClick={() => setShowRanglisteDialog(true)}/>
                </div>
            }
            {tournament.tournamentstatus === "neu" && isKODKO &&
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-user-plus" label="Setzliste bearbeiten"
                            onClick={() => setShowSetzlisteKoDialog(true)}/>
                </div>
            }
            {tournament.tournamentstatus === "neu" && isTurnierMitGruppenphase &&
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-user-plus" label="Setzliste bearbeiten"
                            onClick={() => setShowSetzlisteGruppeDialog(true)}/>
                </div>
            }
            {tournament.tournamentstatus !== "abgeschlossen" && isTurnierMitGruppenphase &&
                <div style={{marginTop: 10}}>
                    <Button icon={PrimeIcons.COG} label="Modus für Gruppen verändern"
                            onClick={() => setShowChangeModusGroup(true)}/>
                </div>
            }
            {(tournament.tournamentstatus === "neu" && !isLigaspieltag && !tournament.online) && <>
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-user-edit" label="Teilnehmer verwalten/bearbeiten"
                            onClick={() => setShowPlayerDialog(true)}/>
                </div>
            </>
            }
            {tournament.tournamentstatus !== "abgeschlossen" &&
                <div style={{marginTop: 10}}>
                    <Button label="Eventleiter verwalten" icon="pi pi-key"
                            onClick={() => setShowAdministratoren(true)}/>
                </div>
            }
            {!tournament.folgeTurnier && !tournament.luckyLoserTurnier &&
                <div style={{marginTop: 10}}>
                    <Button icon="pi pi-copy" label="Event kopieren"
                            onClick={() => setShowCopyDialog(true)}/>
                </div>
            }
            <div style={{marginTop: 10}}>
                <ExportPlayersButton players={tournament.tournamentPlayerEntities}/>
            </div>
        </div>
    }

    if (showAsSidebar) {
        return <DHDefaultDrawer onClose={() => onClose()} show={true} title="Aktionen" children={<>
            <div style={{marginTop: 10, padding: 10}}>
                <Button onClick={() => onClose()} label="Bereich schliessen"/>
            </div>
            {getDataArea()}
        </>}/>
    } else {
        return <>
        {getDataArea()}
        </>
    }
}

LigaAktionenComponent.propTypes = {
    tournament: PropTypes.object.isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    callBackCloseDialog: PropTypes.func.isRequired,
    onClearMessages: PropTypes.func.isRequired,
};

export default LigaAktionenComponent;
