import React, {useEffect, useState} from "react";
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PlayerUtil from "../../../util/PlayerUtil";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";


export default function LigaspieltagEdartDetailStatisikArea({tournamentId, games, players, publicView}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [statistiks, setStatisiks] = useState([]);

    useEffect(() => {
        if( !tournamentId) {
            return;
        }
        ladeDaten();

    }, [tournamentId]);

    const ladeDaten = () => {
        let url;
        if( publicView ) {
            url = ConfigUtil.getConfig().resourceUrls.public + "/ligaspieltag/edart/statistik/tournament/"+ tournamentId;
        } else {
            url = ConfigUtil.getConfig().resourceUrls.tournament + "/ligaspieltag/edart/statistik/tournament/"+ tournamentId;
        }
        FetchUtil.fetchGet(url,
            json => {
                setStatisiks(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Daten: " + error.message);
            }
        );
    }

    const getHighlightString = (values) => {
        if( values.length === 0 ) {
            return "-";
        }
        let sortedValues = values.sort();
        let returnString = "";
        for (const value of sortedValues) {
            returnString += value + ", ";
        }
        return returnString;
    }

    const getRowsEinzelneSpiele = () => {
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 14, padding:5};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 14, padding:5};

        let background = false;
        let rows = [];
        for (const game of games) {
            let ergebnis = game.anzahlPlayer1 !== undefined && game.anzahlPlayer1 !== null ? game.anzahlPlayer1 + ":" + game.anzahlPlayer2 : "-";
            let statistik = statistiks.filter(t => t.gameId === game.id)[0];
            let style = background ? styleWithBackground : styleWithoutBackground;
            let spiel = (game.player1Id ? PlayerUtil.getSpielerName(players, game.player1Id) : "-") + " vs. "  + (game.player2Id ? PlayerUtil.getSpielerName(players, game.player2Id) : "-");
            rows.push(<TableRow key={"key_row_" + game.id}>
                    <TableCell style={style} >{game.counter}</TableCell>
                    <TableCell style={style} >{spiel}</TableCell>
                    <TableCell style={style} >{ergebnis}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple20 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple19 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple18 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple17 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.triple16 : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.bull : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik.schwarzer : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik._171er : 0}</TableCell>
                    <TableCell style={style} >{statistik ? statistik._180er : 0}</TableCell>
                    <TableCell style={style} >{statistik ? getHighlightString(statistik.highscores) : "-"}</TableCell>
                    <TableCell style={style} >{statistik ? getHighlightString(statistik.highfinishes) : "-"}</TableCell>
                </TableRow>);
            background = !background;
        }
        return rows;
    }

    const getTableEinzelneSpiele = () => {
        return <TableContainer style={{ maxHeight: "100%", padding:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:14}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:5, width:20}}>#</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:5}}>Spiel</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Erg.</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T20</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T19</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T18</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T17</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T16</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Bull</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Schw.</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>171</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>180</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:100}}>Highscores</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:100}}>Highfinishes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRowsEinzelneSpiele()}
                </TableBody>
            </Table>
        </TableContainer>
    }

    const getTableGesamt = () => {
        return <TableContainer style={{ maxHeight: "100%", padding:0 }}>
            <Table stickyHeader style={{backgroundColor:"#022836", color:"white", fontSize:14}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:14, padding:5}}>Name</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Spiele</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Gewonnen</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Verloren</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T20</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T19</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T18</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T17</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>T16</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Bull</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>Schw.</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>171</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:50}}>180</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:100}}>Highscores</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", textAlign:"center", fontSize:14, padding:5, width:100}}>Highfinishes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRowsGesamt()}
                </TableBody>
            </Table>
        </TableContainer>
    }

    const getRowsGesamt = () => {
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 14, padding:5};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 14, padding:5};

        let gesamtSpiele = 0;
        let gesamtGewonnen = 0;
        let gesamtVerloren = 0;
        let gesamtTriple20 = 0;
        let gesamtTriple19 = 0;
        let gesamtTriple18 = 0;
        let gesamtTriple17 = 0;
        let gesamtTriple16 = 0;
        let gesamtBull = 0;
        let gesamtSchwarzer = 0;
        let gesamtThrow171er = 0;
        let gesamtThrow180er = 0;
        let gesamtHighscores = [];
        let gesamtHighfinishes = [];

        let background = false;
        let rows = [];
        let heimPlayers = players.filter(p => p.heimmannschaft);
        heimPlayers.sort((a, b) => a.name.localeCompare(b.name));
        for (const player of heimPlayers) {
            let tmpGames = games.filter(game => game.anzahlPlayer1 !== null && game.anzahlPlayer1 !== undefined && game.player1Id === player.id);
            let spiele = 0;
            let gewonnen = 0;
            let verloren = 0;
            let triple20 = 0;
            let triple19 = 0;
            let triple18 = 0;
            let triple17 = 0;
            let triple16 = 0;
            let bull = 0;
            let schwarzer = 0;
            let throw171er = 0;
            let throw180er = 0;
            let highscores = [];
            let highfinishes = [];
            for (const game of tmpGames) {
                spiele++;
                gesamtSpiele++;
                if( game.anzahlPlayer1 > game.anzahlPlayer2 ) {
                    gewonnen++;
                    gesamtGewonnen++;
                }
                if( game.anzahlPlayer1 < game.anzahlPlayer2 ) {
                    verloren++;
                    gesamtVerloren++;
                }
                let statistik = statistiks.filter(t => t.gameId === game.id)[0];
                if( statistik) {
                    triple20 = triple20 + statistik.triple20;
                    gesamtTriple20 = gesamtTriple20 + statistik.triple20;
                    triple19 = triple19 + statistik.triple19;
                    gesamtTriple19 = gesamtTriple19 + statistik.triple19;
                    triple18 = triple18 + statistik.triple18;
                    gesamtTriple18 = gesamtTriple18 + statistik.triple18;
                    triple17 = triple17 + statistik.triple17;
                    gesamtTriple17 = gesamtTriple17 + statistik.triple17;
                    triple16 = triple16 + statistik.triple16;
                    gesamtTriple16 = gesamtTriple16 + statistik.triple16;
                    bull = bull + statistik.bull;
                    gesamtBull = gesamtBull + statistik.bull;
                    schwarzer = schwarzer + statistik.schwarzer;
                    gesamtSchwarzer = gesamtSchwarzer + statistik.schwarzer;
                    throw171er = throw171er + statistik._171er;
                    gesamtThrow171er = gesamtThrow171er + statistik._171er;
                    throw180er = throw180er + statistik._180er;
                    gesamtThrow180er = gesamtThrow180er + statistik._180er;
                    highscores = highscores.concat(statistik.highscores);
                    gesamtHighscores = gesamtHighscores.concat(statistik.highscores);
                    highfinishes = highfinishes.concat(statistik.highfinishes);
                    gesamtHighfinishes = gesamtHighfinishes.concat(statistik.highfinishes);
                }
            }

            let style = background ? styleWithBackground : styleWithoutBackground;
            rows.push(<TableRow key={"key_row_gesamt_" + player.id}>
                <TableCell style={style} >{PlayerUtil.getSpielerName(players, player.id)}</TableCell>
                <TableCell style={style} >{spiele}</TableCell>
                <TableCell style={style} >{gewonnen}</TableCell>
                <TableCell style={style} >{verloren}</TableCell>
                <TableCell style={style} >{triple20}</TableCell>
                <TableCell style={style} >{triple19}</TableCell>
                <TableCell style={style} >{triple18}</TableCell>
                <TableCell style={style} >{triple17}</TableCell>
                <TableCell style={style} >{triple16}</TableCell>
                <TableCell style={style} >{bull}</TableCell>
                <TableCell style={style} >{schwarzer}</TableCell>
                <TableCell style={style} >{throw171er}</TableCell>
                <TableCell style={style} >{throw180er}</TableCell>
                <TableCell style={style} >{highscores.length > 0 ? getHighlightString(highscores) : "-"}</TableCell>
                <TableCell style={style} >{highfinishes.length > 0 ? getHighlightString(highfinishes) : "-"}</TableCell>
            </TableRow>);
        }
        let style = background ? styleWithBackground : styleWithoutBackground;
        style = {...style, fontWeight:"bold", borderTop:"1px solid black"};
        rows.push(<TableRow key={"key_row_gesamt"}>
            <TableCell style={style} >Gesamt:</TableCell>
            <TableCell style={style} >{gesamtSpiele}</TableCell>
            <TableCell style={style} >{gesamtGewonnen}</TableCell>
            <TableCell style={style} >{gesamtVerloren}</TableCell>
            <TableCell style={style} >{gesamtTriple20}</TableCell>
            <TableCell style={style} >{gesamtTriple19}</TableCell>
            <TableCell style={style} >{gesamtTriple18}</TableCell>
            <TableCell style={style} >{gesamtTriple17}</TableCell>
            <TableCell style={style} >{gesamtTriple16}</TableCell>
            <TableCell style={style} >{gesamtBull}</TableCell>
            <TableCell style={style} >{gesamtSchwarzer}</TableCell>
            <TableCell style={style} >{gesamtThrow171er}</TableCell>
            <TableCell style={style} >{gesamtThrow180er}</TableCell>
            <TableCell style={style} >{gesamtHighscores.length > 0 ? getHighlightString(gesamtHighscores) : "-"}</TableCell>
            <TableCell style={style} >{gesamtHighfinishes.length > 0 ? getHighlightString(gesamtHighfinishes) : "-"}</TableCell>
        </TableRow>);
        return rows;
    }


    return <div style={{backgroundColor:"#022836", padding:10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {getTableGesamt()}
        <br/>
        {getTableEinzelneSpiele()}
    </div>
}