import React, {useEffect, useState} from "react";
import {InputNumber} from "primereact/inputnumber";
import ProgressButton from "../../../general/ProgressButton";
import DHDialog from "../../../general/DHDialog";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

export default function TournamentSchweizerSystemAuslosungDialog({tournament, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const[anzahlGruppen, setAnzahlGruppen] = useState(0)
    const[anzahlRunden, setAnzahlRunden] = useState(0)
    const[working, setWorking] = useState(false)

    useEffect(() => {
        if( tournament ) {
            setAnzahlRunden(tournament.anzahlRunden);
        }
    }, [ tournament ]);

    const starteAuslosung = () => {
        setWorking(true);
        let id = tournament.id;
        let data = {id: id, anzahlGruppen: anzahlGruppen, anzahlRunden: anzahlRunden};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/starttournament",
            data,
            json => {
                setWorking(false);
                setInfoMessage("Auslosung wurde erfolgreich durchgeführt.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + error.message);
                setWorking(false);
            }, 60000
        );
    }

    const getArea = () => {
        let anzahlSpieler = getAnzahlSpieler();
        if(  anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        return <div style={{textAlign: "left", padding: 20}}>
            <div style={{fontWeight: "bold"}}>
                Im Turnier sind {anzahlSpieler} Teilnehmer erfasst.
            </div>
            <div style={{marginTop: 20}}>
                Die Anzahl der Runden im Schweizer System kann in Darthelfer flexibel sein.<br/><br/>
                Es ist sinnvoll, maximal die Hälfte der Spieler als Runden zu haben.<br/><br/>
                z.B. bei 8 Spielern, 4 Runden.<br/><br/>
                Sobald du die Gruppenanzahl auf '1' stellst, kannst du nach deinen Runden eine KO Phase starten, wird eine Gruppenzahl Größer 1 gewählt, ist keine KO Phase möglich.<br/>
                In diesem Fall ist es möglich, dass du du eine Entschiedungsrunde startest.<br/>
                In diesem Fall wird der 1. aus Gruppe 1 gegen den 1. aus Gruppe 2 spielen. Gibt es noch mehr Gruppen, geht es so weiter.<br/>
                <br/>
                Sobald du Anzahl Runden... einstellst, wird nach dem letzten Spiel einer Runde automatisch eine neue Runde gestartet.<br/>
                Du kannst aber jederzeit eine Runde von Hand starten und musst das automatische Starten nicht nutzen.<br/>
                Stelle hier nur die Runden ein die du sicher bist wirklich zu spielen. Eine gestarte Runde kann NICHT gelöscht werden.
            </div>
            <div style={{marginTop: 20}}>
                Anzahl Gruppen:<br/>
                <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={anzahlGruppen}
                             onChange={(e) => setAnzahlGruppen(e.value)}/>
            </div>
            <div style={{marginTop: 20}}>
                Anzahl Runden die automatisch gestartet werden:<br/>
                <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={anzahlRunden}
                             onChange={(e) => setAnzahlRunden(e.value)}/>
            </div>
        </div>;
    }


    const getAnzahlSpieler = () => {
        let anzahlSpieler = "0";

        let players = tournament.tournamentPlayerEntities;
        if( players !== undefined && players !== null) {
            players = players.filter(player => !player.warteliste);
            anzahlSpieler = players.length;
        }
        return anzahlSpieler;
    }

    const canSave = () => {
        const intAnzahlGruppen = parseInt(anzahlGruppen);
        return intAnzahlGruppen > 0 && intAnzahlGruppen < 129;
    }

    return <DHDialog title={"Auslosung starten"} showCloseButton={true} onClose={() => onClose()}
                     buttons={[
                         <ProgressButton text="Auslosung starten" icon="pi pi-check"
                                         working={working}
                                         disabled={!canSave()}
                                         onClick={() => starteAuslosung()} />
                     ]} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {working && <div style={{color: "red"}}>Die Auslosung wird durchgeführt, dies kann etwas dauern!!!</div>}
        {getArea()}
    </>}/>
}