import {isAnyGames, isGruppenturnier, isKratzer, isLiga, isLigaspieltag, isRoundRobin, isSchweizerSystem, isWeekendLeague, TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import TournamentRoundRobinOrGruppenturnierAuslosungDialog from "./TournamentRoundRobinOrGruppenturnierAuslosungDialog";
import TournamentLigaAuslosungDialog from "./TournamentLigaAuslosungDialog";
import TournamentKratzerAuslosungDialog from "./TournamentKratzerAuslosungDialog";
import TournamentSchweizerSystemAuslosungDialog from "./TournamentSchweizerSystemAuslosungDialog";
import React, {useState} from "react";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import ConfirmProgressDialog from "../../../general/ConfirmProgressDialog";

export default function TournamentAuslosungDialog({tournament, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false)


    const starteWeekendLeague = () => {
        setErrorMessage(undefined);
        setWorking(true);
        let data = {id: tournament.id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.weekendleague + "/starttournament",
            data,
            json => {
                setWorking(false);
                setInfoMessage("Auslosung wurde erfolgreich durchgeführt.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + error.message);
                setWorking(false);
            }
        );
    }

    const starteDefaultAuslosung = () => {
        setErrorMessage(undefined);
        setWorking(true);
        let data = {id: tournament.id};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/starttournament",
            data,
            json => {
                setWorking(false);
                setInfoMessage("Auslosung wurde erfolgreich durchgeführt.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + error.message);
                setWorking(false);
            }
        );
    }

    const isKoDkoOrWeekendLeague = () => {
        return tournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id ||
            tournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id ||
            tournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id ||
            tournament.turniersystem === TOURNAMENTSYSTEMS.WEEKENDLEAGUE.id;
    }

    if (isWeekendLeague(tournament.turniersystem)) {
        return <ConfirmProgressDialog visible={true}
                                      working={working}
                                      callBackOnNein={() => onClose()}
                                      callBackOnJa={() => starteWeekendLeague()}
                                      header="Weekend League starten"
                                      textOben={<>
                                          <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                                              onClearErrorMessage={() => setErrorMessage(undefined)}/>
                                      </>
                                      }
                                      textUnten="Möchtest du die Weekend League wirklich starten?"/>
    } else if (isAnyGames(tournament.turniersystem)) {
        return <ConfirmProgressDialog visible={true}
                                      working={working}
                                      callBackOnNein={() => onClose()}
                                      callBackOnJa={() => starteDefaultAuslosung()}
                                      header="Turnier starten"
                                      textOben={<>
                                          <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                                              onClearErrorMessage={() => setErrorMessage(undefined)}/>
                                      </>
                                      }
                                      textUnten="Möchtest du das Turnier wirklich starten?"/>
    } else if (isKoDkoOrWeekendLeague()) {
        return <ConfirmProgressDialog visible={true}
                                      working={working}
                                      callBackOnNein={() => onClose()}
                                      callBackOnJa={() => starteDefaultAuslosung()}
                                      header="Auslosung starten"
                                      textOben={<>
                                          <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                                              onClearErrorMessage={() => setErrorMessage(undefined)}/>
                                          <div>
                                              Sobald die Auslosung gestartet wurde, steht diese final fest. Eine Änderung ist nicht mehr vorgesehen.
                                          </div>
                                      </>
                                      }
                                      textUnten="Möchtest du die Auslosung wirklich starten?"/>
    } else if (isLigaspieltag(tournament.turniersystem)) {
        return <ConfirmProgressDialog visible={true}
                                      working={working}
                                      callBackOnNein={() => onClose()}
                                      callBackOnJa={() => starteDefaultAuslosung()}
                                      header="Ligaspieltag starten"
                                      textOben={
                                          <div>
                                              <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                                                  onClearErrorMessage={() => setErrorMessage(undefined)}/>
                                              <div>
                                                  Sobald der Spieltag gestartet wurde, steht dieser final fest. Eine Änderung ist nicht mehr vorgesehen.
                                              </div>
                                              <div style={{display: "flex", alignContent: "center", alignItems: "center", marginTop: 10}}>
                                                  <div>
                                                      <img src="/images/ausrufezeichen.png" style={{height: 20, marginRight: 10}}/>
                                                  </div>
                                                  <div>
                                                      Das Paarkreuzsystem kann ausschließlich vor dem Starten des Ligaspieltages geändert werden.<br/>
                                                      Sofern gewünscht nimm vor dem Starten die Einstellung für das Paarkreuzsystem vor.
                                                  </div>
                                              </div>
                                          </div>
                                      }
                                      textUnten="Möchtest du den Ligaspieltag wirklich starten?"/>
    } else if (isGruppenturnier(tournament.turniersystem) || isRoundRobin(tournament.turniersystem)) {
        return <TournamentRoundRobinOrGruppenturnierAuslosungDialog onRefresh={onRefresh} onClose={onClose} tournament={tournament}/>
    } else if (isLiga(tournament.turniersystem)) {
        return <TournamentLigaAuslosungDialog onRefresh={onRefresh} onClose={onClose} tournament={tournament}/>
    } else if (isSchweizerSystem(tournament.turniersystem)) {
        return <TournamentSchweizerSystemAuslosungDialog onRefresh={onRefresh} onClose={onClose} tournament={tournament}/>
    } else if (isKratzer(tournament.turniersystem)) {
        return <TournamentKratzerAuslosungDialog onRefresh={onRefresh} onClose={onClose} tournament={tournament}/>
    } else {
        return <div>NOT SUPPORTED</div>
    }
}


