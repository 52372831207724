import React, {useState} from "react";
import {InputNumber} from "primereact/inputnumber";
import ProgressButton from "../../../general/ProgressButton";
import DHDialog from "../../../general/DHDialog";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

export default function TournamentKratzerAuslosungDialog({tournament, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const[anzahlGames, setAnzahlGames] = useState(0)
    const[working, setWorking] = useState(false)

    const starteAuslosung = () => {
        setWorking(true);
        let id = tournament.id;
        let data = {id: id, anzahlGames: anzahlGames};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/starttournament",
            data,
            json => {
                setWorking(false);
                setInfoMessage("Auslosung wurde erfolgreich durchgeführt.");
                onRefresh();
                onClose();
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler beim Durchführen der Auslosung: " + error.message);
                setWorking(false);
            }, 60000
        );
    }

    const getArea = () => {
        let anzahlSpieler = getAnzahlSpieler();
        if (anzahlSpieler === 0) {
            return <div>Turnier mit 0 Teilnehmern kann nicht gestartet werden.</div>
        }
        return <div style={{textAlign: "left", height: 400}}>
            <div className="grid">
                <p style={{verticalAlign: "bottom"}}>Es sind <span style={{fontWeight:"bold", fontSize:25, marginRight:5, marginLeft:5}}>{anzahlSpieler}</span> Spieler (ohne Warteliste) für das Turnier '{tournament.bezeichnung}' angelegt.</p>
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                Bitte gebe die Anzahl der Spiele ein. Beispiel: du hast 40 Spieler und gibst Anzahl Games 10 ein, dann sind in jedem Game 4 Spieler.<br/>
                Sofern dir eine Auslosung bzgl. Aufteilung nicht gefällt, kannst du einfach eine neue Spielrunde auslosen.
            </div>
            <div className="grid" style={{marginTop:"20px"}}>
                <div style={{float:"left", width:150}}>
                    Anzahl Games:
                </div>
                <div style={{float:"left"}}>
                    <InputNumber min={1} style={{width: "80px", marginRight: "5px"}} value={anzahlGames}
                                 onChange={(e) => setAnzahlGames(e.value)}/>

                </div>
            </div>
        </div>;
    }


    const getAnzahlSpieler = () => {
        let anzahlSpieler = "0";

        let players = tournament.tournamentPlayerEntities;
        if( players !== undefined && players !== null) {
            players = players.filter(player => !player.warteliste);
            anzahlSpieler = players.length;
        }
        return anzahlSpieler;
    }

    const canSave = () => {
        return anzahlGames > 0;
    }

    return <DHDialog title={"Auslosung starten"} showCloseButton={true} onClose={() => onClose()}
                     buttons={[
                         <ProgressButton text="Auslosung starten" icon="pi pi-check"
                                         working={working}
                                         disabled={!canSave()}
                                         onClick={() => starteAuslosung()} />
                     ]} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        {working && <div style={{color: "red"}}>Die Auslosung wird durchgeführt, dies kann etwas dauern!!!</div>}
        {getArea()}
    </>}/>
}