import React from 'react'
import {Messages} from "primereact/messages";
import {Toast} from 'primereact/toast';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {getModusNameById} from "../../general/GameModusComponent";
import {TabPanel, TabView} from "primereact/tabview";
import {Sponsoren, Tabelle171er, Tabelle180er, TabelleBestDarts, TabelleHighfinishes, TabelleHighscores, TabelleLowDarts, TabelleWorstDarts, TournamentGameShortComponent} from "./ligaComponents";
import {Button} from "primereact/button";
import LigaTeilnehmerArea from "./LigaTeilnehmerArea";
import PropTypes from "prop-types";
import LigaZuschauenArea from "./LigaZuschauenArea";
import Turnierbaum from "../tournament/Turnierbaum";
import Chat from "../../general/Chat";
import ConfirmKennwortDialog from "../../general/ConfirmKennwortDialog";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";
import Turniergruppen from "../tournament/Turniergruppen";
import LigaKalenderArea from "./LigaKalenderArea";
import {Dropdown} from "primereact/dropdown";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {NavLink} from "react-router-dom";
import SpielbarePhaseDialog from "./SpielbarePhaseDialog";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import {isGruppenturnier} from "../../../constants/tournamentSystems";
import LigaUrkunden from "./LigaUrkunden";
import TurnierVerwaltenDialog from "../tournament/TurnierVerwaltenDialog";
import RefreshCounterComp from "../../RefreshCounterComp";
import KoDKOGameListe from "../tournament/KoDKOGameListe";
import TurnierQRCode from "./TurnierQRCode";
import TournamentGroupManuellDialog from "../tournament/TournamentGroupManuellDialog";
import RundUmsBoardDialog from "../veranstalter/board/RundUmsBoardDialog";
import PublicTournamentRanglisteTab from "../public/tournaments/PublicTournamentRanglisteTab";
import TurnierGruppenphaseAbschliessenDialog from "../tournament/TurnierGruppenphaseAbschliessenDialog";
import TournamentPlatzierungTab from "./TournamentPlatzierungTab";
import PublicTournamentStatistikTab from "../public/tournaments/PublicTournamentStatistikTab";
import LigaAktionenComponent from "../tournament/tabs/LigaAktionenComponent";
import {PrimeIcons} from "primereact/api";
import {ProgressSpinner} from "primereact/progressspinner";
import SchweizerSystemRunden from "../tournament/SchweizerSystemRunden";
import TournamentSchweizerSystemAuslosungDialog from "../tournament/auslosung/TournamentSchweizerSystemAuslosungDialog";
import TournamentRoundRobinOrGruppenturnierAuslosungDialog from "../tournament/auslosung/TournamentRoundRobinOrGruppenturnierAuslosungDialog";

class TurnierGruppenDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {readonly: false, zeigeBeschreibungVoll: false,
            showKennwortDialog:false,kennwort:"", showAuslosungDialog: false, koTableView: false, luckyLoserTableView: false,
            showDeleteConfirmDialog: false, showSpielbarePhaseDialog: false, showEditDialog: false, showAktionen:false, showEditFolgeTurnierDialog: false, showEditLuckyLoserTurnierDialog: false, showRundUmsBoardDialog:false,
            showGroupManuellDialog: false, selectedGroup: 1};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.ladeTurnierDaten = this.ladeTurnierDaten.bind(this);
        this.getSpielerName = this.getSpielerName.bind(this);
        this.getUsernamesByUserId = this.getUsernamesByUserId.bind(this);
        this.isSpielerInaktiv = this.isSpielerInaktiv.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showInfo(message) {
        this.toast.show({
            severity: 'success',
            summary: 'Erfolgreich...',
            detail: message
        })
    }

    showError(message) {
        this.messages.show({
            severity: 'error',
            sticky: 'true',
            summary: 'Fehler...',
            detail: message
        });
    }


    ladeDaten() {
        this.messages.clear();
        this.ladeTurnierDaten();
    }

    teilnahmeDoppelAendern() {
        this.messages.clear();
        let mussKennwortAbgefragtWerden = this.state.turnierGruppe.kennwort !== null && this.state.turnierGruppe.kennwort !== undefined && this.state.turnierGruppe.kennwort !== "";
        if( mussKennwortAbgefragtWerden && this.state.kennwort === "") {
            this.setState({showKennwortDialog:true});
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeDoppelAendern?tournamentid=" + this.state.turnierGruppe.id +
            "&doppelpaarungid=" + this.state.selectedDoppelpaarung.id + "&kennwort=" + this.state.kennwort,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Speichern war erfolgreich!")
                    this.ladeDaten();
                } else {
                    this.showError(message);
                    this.setState({showKennwortDialog: false, kennwort:""});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            }
        );
    }

    teilnahmeAendern() {
        this.messages.clear();
        let mussKennwortAbgefragtWerden = this.state.turnierGruppe.kennwort !== null && this.state.turnierGruppe.kennwort !== undefined && this.state.turnierGruppe.kennwort !== "";
        if( mussKennwortAbgefragtWerden && this.state.kennwort === "") {
            this.setState({showKennwortDialog:true});
            return
        }

        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeAendern?tournamentid=" + this.state.turnierGruppe.id + "&kennwort=" + this.state.kennwort,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Speichern war erfolgreich!")
                    this.ladeDaten();
                } else {
                    this.showError(message);
                    this.setState({showKennwortDialog: false, kennwort:""});
                }
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
                this.setState({showKennwortDialog: false, kennwort:""});
            }
        );
    }

    teilnahmeBeenden() {
        this.messages.clear();
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeAendern?tournamentid=" + this.state.turnierGruppe.id,
            {},
            json => {
                this.showInfo("Speichern war erfolgreich!")
                this.ladeDaten();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }


    ladeTurnierDaten() {
        if (this.props.turnierid === null || this.props.turnierid === undefined) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/gruppenturnier/" + this.props.turnierid;
        FetchUtil.fetchGet(url,
            json => {
                let readonly = false;//json.tournamentDto.tournamentstatus === "abgeschlossen";
                this.setState({turnierGruppe: json.turnierGruppe,
                    gruppeModelWrapperListe: json.gruppeModelWrapperListe,
                    turnierKO : json.turnierKO,
                    turnierLuckyLoser : json.turnierLuckyLoser,
                    modelKO: json.modelKO,
                    modelLuckyLoser: json.modelLuckyLoser,
                    usernames: json.usernameDtos,
                    userGames: json.userGamesDtos,
                    gruppenTabellen: json.gruppenTabellen,
                    bestplatzierteDtos: json.bestplatzierteDtos,
                    gameMeetingKeyDtos: json.gameMeetingKeyDtos,
                    readonly: readonly,
                    showKennwortDialog: false,
                    tabelleUrkundeDtos:json.tabelleUrkundeDtos,
                    ranglisteHighfinishes: json.ranglisteWrapper.ranglisteHighfinishes,
                    ranglisteHighscores: json.ranglisteWrapper.ranglisteHighscores,
                    rangliste180er: json.ranglisteWrapper.rangliste180er,
                    rangliste171er: json.ranglisteWrapper.rangliste171er,
                    bestDarts: json.ranglisteWrapper.bestDarts,
                    worstDarts: json.ranglisteWrapper.worstDarts,
                    lowDarts: json.ranglisteWrapper.lowDarts,
                    statistik: json.ranglisteWrapper.statistikDto,
                    playerStatistiks: json.ranglisteWrapper.playerStatistiks,
                    doppelpaarungen: json.doppelpartnerDtos,
                    boardGames: json.boardGames,
                    tournamentBeschreibungenKO: json.tournamentBeschreibungenKO,
                    boardReihenfolgeKO: json.boardReihenfolgeKO,
                    tournamentBeschreibungenLuckyLoser: json.tournamentBeschreibungenLuckyLoser,
                    boardReihenfolgeLuckyLoser: json.boardReihenfolgeLuckyLoser,
                    kennwort:""});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden des Turniers: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden des Turniers: " + error.message);
            });
    }

    getUsernamesByUserId(userId) {
        for (const username of this.state.usernames) {
            if (username.userId === userId) {
                return username;
            }
        }
        return undefined;
    }

    isAktuellerUserAdministrator() {
        if( this.state.turnierGruppe.erstellerUserId === this.props.userId) {
            return true;
        }
        for (const admin of this.state.turnierGruppe.administratoren) {
            if (admin.userId === this.props.userId) {
                return true;
            }
        }
        return false;
    }

    isAktuellerUserInLigaVorhanden() {
        if (this.state.turnierGruppe.tournamentPlayerEntities === null || this.state.turnierGruppe.tournamentPlayerEntities === undefined) {
            return false;
        }

        if( this.state.turnierGruppe.doppelturnier) {
            for (const doppelDto of this.state.turnierGruppe.doppelpartnerDtos) {
                if( doppelDto.userId1 === this.props.userId || doppelDto.userId2 === this.props.userId) {
                    return true;
                }
            }

        } else {
            for (const player of this.state.turnierGruppe.tournamentPlayerEntities) {
                if (player.userId === this.props.userId && !player.inaktiv) {
                    return true;
                }
            }
        }
        return false;
    }

    getTurnierBetreiber() {
        for (const leagueoperator of this.props.leagueoperators) {
            if( leagueoperator.id === this.state.turnierGruppe.leagueOperatorId) {
                return leagueoperator;
            }
        }
        return undefined;
    }

    getModusRow(key, bezeichner, modusElement) {
        let wert = "";
        if( bezeichner !== null && bezeichner !== undefined) {
            wert += "Modus: " + bezeichner;
        }
        wert += " Gametype: " + modusElement.gameType + " - ";
        if( modusElement.startpunkte !== null) {
            wert += " Startpunkte: " + modusElement.startpunkte;
        }
        if( modusElement.modusid !== null) {
            wert += " Modus: " + getModusNameById(modusElement.modusid);
        }
        if( modusElement.zufallszahlen !== null) {
            wert += " Zufallsz.: " + (modusElement.zufallszahlen ? "ja" : "nein") + " - Cutthroat: " + (modusElement.cutthroat ? "ja" : "nein")
        }

        return <div key={key}>{wert} {modusElement.sets > 1 ? " - sets: " + modusElement.sets : ""} - ft legs {modusElement.firstToLegs}</div>
    }

    isGruppenphaseSetModus() {
        let modusGruppenphase = this.state.turnierGruppe.tournamentModusEntities.find(element => element.modusTyp === "gruppenturnier");
        return modusGruppenphase.sets > 1;
    }

    getTurnierDetailArea() {
        if (this.state.turnierGruppe === null || this.state.turnierGruppe === undefined) {
            return <div>Es sind noch keine Daten geladen...</div>
        }

        let ligaBetreiber = this.getTurnierBetreiber();
        let ligaBetreiberName = "";
        let ligaBetreiberHomepage = "";
        let ligaBetreiberSocialmedia = "";
        if( ligaBetreiber !== undefined) {
            ligaBetreiberName = ligaBetreiber.name + " / " + ligaBetreiber.shortname;
            ligaBetreiberHomepage = ligaBetreiber.homepage;
            ligaBetreiberSocialmedia = ligaBetreiber.socialmedia;
        }

        let turnier = this.state.turnierGruppe;
        let turnierKO = this.state.turnierKO;
        let isFolgeTurnierRunning = turnierKO !== null && turnierKO !== undefined;
        let isLuckyLoserTurnierRunning = this.state.turnierLuckyLoser !== null && this.state.turnierLuckyLoser !== undefined;

        let modusArray = [];
        let modusGruppenphase = turnier.tournamentModusEntities.find(element => element.modusTyp === "gruppenturnier");
        modusArray.push(this.getModusRow("modus_gruppenturnier", "Gruppenphase", modusGruppenphase));
        if( turnierKO !== null && turnierKO !== undefined) {
            let modusGewinnerseite = turnierKO.tournamentModusEntities.find(element => element.modusTyp === "gewinnerseite");
            let modusVerliererseite = turnierKO.tournamentModusEntities.find(element => element.modusTyp === "verliererseite");
            let modusAchtelfinale = turnierKO.tournamentModusEntities.find(element => element.modusTyp === "achtelfinale");
            let modusViertelfinale = turnierKO.tournamentModusEntities.find(element => element.modusTyp === "viertelfinale");
            let modusHalbfinale = turnierKO.tournamentModusEntities.find(element => element.modusTyp === "halbfinale");
            let modusFinale = turnierKO.tournamentModusEntities.find(element => element.modusTyp === "finale");

            modusArray.push(<div key="modus_placeholderrow" style={{marginTop:10}}>Modus KO/DKO Phase:</div>);
            if( modusGewinnerseite !== null && modusGewinnerseite !== undefined) {
                let bezeichner = "";
                if( turnier.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id ) {
                    bezeichner = "Gewinnerseite:";
                }
                modusArray.push(this.getModusRow("modus_gewinnerseite", bezeichner, modusGewinnerseite));
            }
            if( modusVerliererseite !== null && modusVerliererseite !== undefined) {
                modusArray.push(this.getModusRow("modus_verliererseite", "Verliererseite:", modusVerliererseite));
            }
            if( modusAchtelfinale !== null && modusAchtelfinale !== undefined) {
                modusArray.push(this.getModusRow("modus_achtelfinale", "Achtelfinale:", modusAchtelfinale));
            }
            if( modusViertelfinale !== null && modusViertelfinale !== undefined) {
                modusArray.push(this.getModusRow("modus_viertelfinale", "Viertelfinale:", modusViertelfinale));
            }
            if( modusHalbfinale !== null && modusHalbfinale !== undefined) {
                modusArray.push(this.getModusRow("modus_halbfinale", "Halbfinale:", modusHalbfinale));
            }
            if( modusFinale !== null && modusFinale !== undefined) {
                modusArray.push(this.getModusRow("modus_finale", "Finale:", modusFinale));
            }
        }

        let fixedColumWidth = 140;
        return <div style={{textAlign: "left"}}>
            <div className="grid" style={{marginTop: 10}}>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Bezeichnung:</div>
                        <div className="col">{turnier.bezeichnung}</div>
                    </div>
                    {turnier.tournamentSponsorenEntities.length > 0 && <Sponsoren sponsoren={turnier.tournamentSponsorenEntities} />}
                    {ligaBetreiberName !== "" && <>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierbetreiber:</div>
                            <div className="col">{ligaBetreiberName}</div>
                        </div>
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}></div>
                            <div className="col">
                                {ligaBetreiberHomepage ? <a href={ligaBetreiberHomepage} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/homepage.png" alt="Homepage" style={{width:30}}/></a> :"" }
                                {ligaBetreiberSocialmedia ? <a href={ligaBetreiberSocialmedia} target="_blank" rel="noopener noreferrer" style={{color: "white"}}><img src="/images/facebook.png" alt="Facebook" style={{marginLeft:10, width:30}}/></a> :"" }
                            </div>
                        </div>
                    </>
                    }
                    {turnier.anmeldeende !== null && turnier.anmeldeende !== undefined &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Anmeldeende:</div>
                            <div className="col">{turnier.anmeldeendeMitUhrzeitString}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Startzeitpunkt:</div>
                        <div className="col">{turnier.beginnMitUhrzeitString}</div>
                    </div>
                    {this.getBeschreibungArea(fixedColumWidth)}
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Ersteller:</div>
                        <div className="col">{turnier.erstellerName}</div>
                    </div>
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierleitung:</div>
                        <div className="col">{this.getAdministratoren()}</div>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Turnierstatus:</div>
                        <div className="col">{turnier.tournamentstatus}</div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            {modusArray}
                        </div>
                    </div>
                    {(turnier.averageObergrenze !== null && turnier.averageObergrenze !== undefined) &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Avg. Obergrenze:</div>
                            <div
                                className="col">{turnier.averageObergrenze}</div>
                        </div>
                    }
                    {turnier.rangliste &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Ranglistenturnier:</div>
                            <div className="col">{turnier.rangliste ? "Ja" : "Nein"}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Anzahl Spieler:</div>
                        <div
                            className="col">{turnier.tournamentPlayerEntities !== null && turnier.tournamentPlayerEntities !== undefined ? turnier.tournamentPlayerEntities.length : "0"}</div>
                    </div>
                    {(turnier.maxAnzahlTeilnehmer !== null && turnier.maxAnzahlTeilnehmer !== undefined) &&
                        <div className="grid">
                            <div className="col-fixed" style={{width: fixedColumWidth}}>Max. Anzahl Teilnehmer:</div>
                            <div
                                className="col">{turnier.maxAnzahlTeilnehmer}</div>
                        </div>
                    }
                    <div className="grid">
                        <div className="col-fixed" style={{width: fixedColumWidth}}>Doppelturnier:</div>
                        <div className="col">{turnier.doppelturnier ? "Ja" : "Nein"}{turnier.dyp && " - DYP"}</div>
                    </div>
                </div>
            </div>
            {turnier.tournamentstatus === "neu" && turnier.online && turnier.doppelturnier && !this.isAktuellerUserInLigaVorhanden() &&
                <>
                    <div className="grid" style={{fontSize: 12, marginBottom: 15}}>
                        Hier kannst du deinen Doppelnamen auswählen und damit am Turnier teilnehmen. Sofern du hier keinen Doppelnamen findest ist für dich noch keine Doppelpaarung angelegt, im Profil kannst du diese anlegen/verwalten oder Details sehen.
                </div>
                <div className="grid">
                    <Dropdown value={this.state.selectedDoppelpaarung} options={this.state.doppelpaarungen}
                              optionLabel="name"
                              onChange={(e) => {
                                  this.setState({selectedDoppelpaarung: e.value})
                              }}
                              placeholder="Eingabe..." style={{width: 300}}/>
                    <Button label="Am Doppelturnier teilnehmen" icon="pi pi-plus"
                            onClick={() => this.teilnahmeDoppelAendern()}
                            disabled={this.state.selectedDoppelpaarung === undefined || this.state.selectedDoppelpaarung === null}
                            style={{height: 40, width: 350, marginLeft: 10, marginRight: 10}}/>
                </div>
            </>
            }
            <div className="grid" style={{marginTop:10}}>
                {turnier.tournamentstatus === "neu" && !turnier.doppelturnier && !this.isAktuellerUserInLigaVorhanden() && turnier.online &&
                <Button label="Am Turnier teilnehmen" icon="pi pi-plus" onClick={() => this.teilnahmeAendern()}
                        style={{height: 40, width: 350, marginTop:5, marginRight: 5}}/>
                }
                {turnier.tournamentstatus === "neu" && this.isAktuellerUserInLigaVorhanden() && turnier.online &&
                <Button label="Aus Turnier austreten, nicht teilnehmen" icon="pi pi-minus"
                        onClick={() => this.teilnahmeBeenden()}
                        style={{height: 40, width: 350, marginTop:5, marginRight: 5}}/>
                }
                {turnier.tournamentstatus === "gestartet" && this.isAktuellerUserInLigaVorhanden() && turnier.online &&
                <Button label="Teilnahme beenden" icon="pi pi-plus" onClick={() => this.setState({showDeleteConfirmDialog: true})}
                        style={{height: 40, width: 350, marginTop:5, marginRight: 5}}/>
                }
                {(this.isAktuellerUserAdministrator() && !this.state.readonly && isFolgeTurnierRunning && turnier.online) &&
                    <Button label="KO/DKO Spielbare Phase" icon="pi pi-unlock"
                            onClick={() => this.setState({showSpielbarePhaseDialog: true})}
                            style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                }
                {turnier.tournamentstatus === "neu" && this.isAktuellerUserAdministrator() &&
                    <Button label="Turnier starten" icon="pi pi-align-justify"
                            onClick={() => this.setState({showAuslosungDialog: true})}
                            style={{height: 40, width: 200, marginTop:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() && turnier.auslosungVonHand && turnier.tournamentstatus === "neu" &&
                    <Button label="Gruppeneinteilung" icon="pi pi-pencil"
                            onClick={() => this.setState({showGroupManuellDialog: true})}
                            style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Bearbeiten" icon="pi pi-pencil"
                            onClick={() => this.setState({showEditDialog: true})}
                            style={{height: 40, width: 150, marginTop:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() &&
                    <Button label="Aktionen" icon={PrimeIcons.BOLT}
                            onClick={() => this.setState({showAktionen: true})}
                            style={{height: 40, width: 150, marginTop:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() && isFolgeTurnierRunning &&
                    <Button label="Folgeturnier bearbeiten" icon="pi pi-pencil"
                            onClick={() => this.setState({showEditFolgeTurnierDialog: true})}
                            style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                }
                {this.isAktuellerUserAdministrator() && isLuckyLoserTurnierRunning &&
                    <Button label="Luckyloserturnier bearbeiten" icon="pi pi-pencil"
                            onClick={() => this.setState({showEditLuckyLoserTurnierDialog: true})}
                            style={{height: 40, width: 300, marginTop:5, marginRight: 5}}/>
                }
                {turnier.tournamentstatus !== "abgeschlossen" && !turnier.online && this.isAktuellerUserAdministrator() &&
                    <Button label="Board/Schreiber verwalten" icon="pi pi-book" onClick={() => this.setState({showRundUmsBoardDialog: true})}
                            style={{height: 40, marginTop:5, marginRight: 5}}/>
                }
                <RefreshCounterComp text="Auto-Refresh" onRefresh={() => this.ladeDaten()}/>
            </div>
        </div>
    }

    getHightlightsArea() {
        return <div className="grid">
            <Tabelle180er rangliste180er={this.state.rangliste180er} getSpielerName={this.getSpielerName}/>
            <Tabelle171er rangliste171er={this.state.rangliste171er} getSpielerName={this.getSpielerName}/>
            <TabelleHighfinishes ranglisteHighfinishes={this.state.ranglisteHighfinishes} getSpielerName={this.getSpielerName}/>
            <TabelleHighscores ranglisteHighscores={this.state.ranglisteHighscores} getSpielerName={this.getSpielerName}/>
            <TabelleLowDarts lowDarts={this.state.lowDarts}  getSpielerName={this.getSpielerName} />
            <TabelleBestDarts bestDarts={this.state.bestDarts}  getSpielerName={this.getSpielerName} />
            <TabelleWorstDarts worstDarts={this.state.worstDarts}  getSpielerName={this.getSpielerName} />
        </div>;
    }

    getSpielerName(id) {
        if (id === undefined || this.state.turnierGruppe === null || this.state.turnierGruppe === undefined) {
            return "";
        }

        for (let i = 0; i < this.state.turnierGruppe.tournamentPlayerEntities.length; i++) {
            let spieler = this.state.turnierGruppe.tournamentPlayerEntities[i];
            if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                return spieler.name;
            }
        }
        for (let i = 0; i < this.state.turnierKO.tournamentPlayerEntities.length; i++) {
            let spieler = this.state.turnierKO.tournamentPlayerEntities[i];
            if (spieler.id === id || spieler.userId === id || spieler.doppelpartnerId === id) {
                if (spieler.name.length > 25) {
                    return spieler.name.substring(0, 25) + "...";
                }
                return spieler.name;
            }
        }
        return "Freilos";
    }

    getGamesBySpieltag(spieltag) {
        let liga = this.state.turnier
        let gruppenEintraege = liga.tournamentGroupGameEntities;
        let games = []
        if (gruppenEintraege === null || gruppenEintraege === undefined) {
            return games;
        }
        for (const eintrag of gruppenEintraege) {
            if (eintrag.spielrunde === spieltag) {
                games.push(eintrag);
            }
        }
        return games;

    }

    isSpielerInaktiv(spielerid) {
        for (const player of this.state.turnier.tournamentPlayerEntities) {
            if (player.id === spielerid && player.inaktiv) {
                return true;
            }
        }
        return false;
    }

    ermittlePlayerIdVomAktuellenUser() {
        let liga = this.state.turnier;
        if (liga.tournamentPlayerEntities === null || liga.tournamentPlayerEntities === undefined) {
            return undefined;
        }
        for (const player of this.state.turnier.tournamentPlayerEntities) {
            if (player.userId === this.props.userId) {
                return player.id;
            }
        }
        return undefined;
    }

    getStatistikArea() {
        return <div style={{backgroundColor: "#022836", color:"white", textAlign:"left", padding:5}}>
            <PublicTournamentStatistikTab tournamentId={this.state.turnierGruppe.id} participants={this.state.turnierGruppe.tournamentPlayerEntities}
                                          gesamtStatistik={this.state.statistik}
                                          playerStatistiks={this.state.playerStatistiks}/>
        </div>
    }

    getRanglisteArea() {
        return <div style={{backgroundColor: "#022836", padding:10}}>
                <PublicTournamentRanglisteTab participants={this.state.turnierGruppe.ranglistePlayerEntities}
                                              eventOperatorId={this.state.turnierGruppe.eventOperatorId}
                                              rankingId={this.state.turnierGruppe.ranglisteId}/>
            </div>
    }

    getPlatzierungArea() {
        return <div style={{backgroundColor: "#022836", padding:10}}>
            <TournamentPlatzierungTab tournamentId={this.state.turnierGruppe.id}/>
        </div>
    }

    render() {
        let turnier = this.state.turnierGruppe;
        let turnierKo = this.state.turnierKO;
        let turnierLuckyLoser = this.state.turnierLuckyLoser;
        if (turnier === null || turnier === undefined) {
            return <div>
                <Toast ref={(el) => this.toast = el}/>
                <Messages ref={(el) => this.messages = el}/>
                <ProgressSpinner style={{width:100, height:100}}/>
            </div>
        }

        let isTurnierRunning = this.state.turnierGruppe.tournamentstatus !== "neu";
        let isFolgeTurnierRunning = this.state.turnierKO !== null && this.state.turnierKO !== undefined;
        let isLuckyLoserTurnierRunning = this.state.turnierLuckyLoser !== null && this.state.turnierLuckyLoser !== undefined;
        let isTurnierAbgeschlossen = this.state.turnierGruppe.tournamentstatus === "abgeschlossen";
        let isUserInTurnierRelevant = this.isAktuellerUserInLigaVorhanden() || this.isAktuellerUserAdministrator();
        let isSchweizerSystem = turnier.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id;
        let isRoundRobin = turnier.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id;
        let gruppenturnier = isGruppenturnier(turnier.turniersystem);
        let isShowBaum = isFolgeTurnierRunning;
        let tabs = [];

        if( this.isAktuellerUserAdministrator() && turnier.online) {
            tabs.push(<TabPanel header="Admin-Games" key="tab_admin_games">
                    {this.getAdminGamesTab()}
                </TabPanel>
            );
        }

        if( isTurnierAbgeschlossen && (turnier.rangliste || (turnier.ranglisteId !== null && turnier.ranglisteId !== undefined))) {
            tabs.push(<TabPanel header="Rangliste" key="tab_rangliste">
                    {this.getRanglisteArea()}
                </TabPanel>
            );
        }

        if( isTurnierRunning && !isRoundRobin ) {
            tabs.push(<TabPanel header="Platzierung" key="tab_platzierung">
                    {this.getPlatzierungArea()}
                </TabPanel>
            );
        }

        if( isTurnierAbgeschlossen && turnier.urkunden) {
            tabs.push(<TabPanel header="Urkunden" key="tab_urkunden">
                    <LigaUrkunden tournamentId={turnierKo.id}
                                  tabelleUrkundeDtos={this.state.tabelleUrkundeDtos}
                                  rangliste171er={this.state.rangliste171er}
                                  rangliste180er={this.state.rangliste180er}
                                  ranglisteHighscores={ this.state.ranglisteHighscores}
                                  ranglisteHighfinishes={ this.state.ranglisteHighfinishes}
                                  ranglisteBestDarts={this.state.bestDarts}
                                  callbackGetSpielername={this.getSpielerName}/>
                </TabPanel>
            );
        }

        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <Toast ref={(el) => this.toast = el}/>
            <Messages ref={(el) => this.messages = el}/>
            <div className="grid pageHeader" style={{padding: 0, margin: 0, backgroundColor: "#b3ccff"}}>
                <div className="col" style={{marginTop: 10, padding: 0}}>
                    Turnier - Details
                </div>
            </div>
            <div style={{paddingRight: 20, paddingLeft: 20}}>
                {this.getTurnierDetailArea()}
            </div>

            {this.getGamesArea()}
            <TabView style={{width: "100%", paddingTop:10, paddingLeft:10, paddingRight:10, border:"none"}}>
                <TabPanel header="Teilnehmer">
                    {this.getTeilnehmerArea()}
                </TabPanel>
                {isSchweizerSystem &&
                    <TabPanel header="Runden" disabled={!isTurnierRunning}>
                        {this.getSchweizerSystemRunden()}
                    </TabPanel>
                }
                {!isSchweizerSystem &&
                    <TabPanel header="Turniergruppen" disabled={!isTurnierRunning}>
                        {this.getTurnierGruppen()}
                    </TabPanel>
                }
                {isShowBaum &&
                    <TabPanel header="Turnierbaum" disabled={!isFolgeTurnierRunning}>
                        {isFolgeTurnierRunning && !this.state.koTableView &&
                            <div>
                                <div style={{textAlign: "left", marginBottom: 10}}>
                                    <Button icon="pi pi-list"
                                            onClick={() => this.setState({koTableView: !this.state.koTableView})}
                                            style={{marginRight: 10}}/>
                                </div>
                                <Turnierbaum userId={this.props.userId}
                                             turnier={this.state.turnierKO}
                                             tournamentGroup={1}
                                             userGames={this.state.userGames}
                                             setzliste={this.state.setzliste}
                                             model={this.state.modelKO}
                                             readonly={!this.isAktuellerUserAdministrator() || this.state.turnierKO.tournamentstatus === "abgeschlossen"}
                                             boardGames={this.state.boardGames}
                                             tournamentBeschreibungen={this.state.tournamentBeschreibungenKO}
                                             loadData={this.ladeDaten}
                                />
                            </div>
                        }
                        {isFolgeTurnierRunning && this.state.koTableView &&
                            <KoDKOGameListe tournamentPlayerEntities={this.state.turnierKO.tournamentPlayerEntities}
                                            scoringWithDarthelfer={this.state.turnierKO.scoringWithDarthelfer}
                                            tournamentBeschreibungen={this.state.tournamentBeschreibungenKO}
                                            ergebnisse={this.state.turnierKO.tournamentErgebnisEntities}
                                            tournamentId={this.state.turnierKO.id}
                                            tournamentGroup={1}
                                            eventOperatorId={this.state.turnierKO.eventOperatorId}
                                            online={this.state.turnierKO.online}
                                            boardGames={this.state.boardGames}
                                            models={this.state.modelKO}
                                            finaleMitSatzausgleich={this.state.turnierKO.finaleVerliererseiteMussZweiSpieleGewinnen != null && this.state.turnierKO.finaleVerliererseiteMussZweiSpieleGewinnen}
                                            onRefresh={this.ladeDaten}
                                            readonly={!this.isAktuellerUserAdministrator() || this.state.turnierKO.tournamentstatus === "abgeschlossen"}
                                            boardReihenfolge={this.state.boardReihenfolgeKO}
                                            onChangeView={() => this.setState({koTableView: !this.state.koTableView})}/>
                        }
                    </TabPanel>
                }
                {isLuckyLoserTurnierRunning &&
                    <TabPanel header="Lucky Loser">
                        {!this.state.luckyLoserTableView &&
                            <div>
                                <div style={{textAlign: "left", marginBottom: 10}}>
                                    <Button icon="pi pi-list"
                                            onClick={() => this.setState({luckyLoserTableView: !this.state.luckyLoserTableView})}
                                            style={{marginRight: 10}}/>
                                </div>
                                <Turnierbaum userId={this.props.userId}
                                             turnier={this.state.turnierLuckyLoser}
                                             tournamentGroup={1}
                                             userGames={this.state.userGames}
                                             setzliste={this.state.setzliste}
                                             model={this.state.modelLuckyLoser}
                                             readonly={!this.isAktuellerUserAdministrator()}
                                             boardGames={this.state.boardGames}
                                             tournamentBeschreibungen={this.state.tournamentBeschreibungenLuckyLoser}
                                             loadData={this.ladeDaten}
                                />
                            </div>
                        }
                        {this.state.luckyLoserTableView &&
                            <KoDKOGameListe tournamentPlayerEntities={this.state.turnierLuckyLoser.tournamentPlayerEntities}
                                            scoringWithDarthelfer={this.state.turnierLuckyLoser.scoringWithDarthelfer}
                                            tournamentBeschreibungen={this.state.tournamentBeschreibungenLuckyLoser}
                                            ergebnisse={this.state.turnierLuckyLoser.tournamentErgebnisEntities}
                                            tournamentId={this.state.turnierLuckyLoser.id}
                                            tournamentGroup={1}
                                            eventOperatorId={this.state.turnierLuckyLoser.eventOperatorId}
                                            online={this.state.turnierLuckyLoser.online}
                                            boardGames={this.state.boardGames}
                                            models={this.state.modelLuckyLoser}
                                            finaleMitSatzausgleich={this.state.turnierLuckyLoser.finaleVerliererseiteMussZweiSpieleGewinnen != null && this.state.turnierLuckyLoser.finaleVerliererseiteMussZweiSpieleGewinnen}
                                            onRefresh={this.ladeDaten}
                                            readonly={!this.isAktuellerUserAdministrator() || this.state.turnierLuckyLoser.tournamentstatus === "abgeschlossen"}
                                            boardReihenfolge={this.state.boardReihenfolgeLuckyLoser}
                                            onChangeView={() => this.setState({luckyLoserTableView: !this.state.luckyLoserTableView})}/>
                        }
                    </TabPanel>
                }
                <TabPanel header="Highlights" disabled={!isTurnierRunning ||  this.state.turnierGruppe.gameType !== "x01"}>
                    {this.getHightlightsArea()}
                </TabPanel>
                <TabPanel header="Statistik" disabled={!isTurnierRunning ||  this.state.turnierGruppe.gameType !== "x01"}>
                    {this.getStatistikArea()}
                </TabPanel>
                {this.state.turnierGruppe.online &&
                    <TabPanel header="Terminabsprache" disabled={this.state.turnierGruppe.doppelturnier || !isTurnierRunning || isTurnierAbgeschlossen || !isUserInTurnierRelevant}>
                        {<LigaKalenderArea liga={this.state.turnierGruppe} userId={this.props.userId}/>}
                    </TabPanel>
                }
                <TabPanel header="Zuschauen" disabled={!isTurnierRunning || isTurnierAbgeschlossen}>
                    {this.getZuschauenArea()}
                </TabPanel>
                {this.state.turnierGruppe.online &&
                    <TabPanel header="Chat" disabled={!isTurnierRunning || isTurnierAbgeschlossen || !isUserInTurnierRelevant}>
                        {this.getChat()}
                    </TabPanel>
                }
                {turnier.eventOperatorId !== null && turnier.eventOperatorId !== undefined &&
                    <TabPanel header="Links">
                        <TurnierQRCode eventOperatorId={turnier.eventOperatorId} tournamentId={turnier.id} tournamentSystem={turnier.tournamentstatus} onlineTournament={turnier.online} bezeichnung={turnier.bezeichnung}/>
                    </TabPanel>
                }
                {tabs}
            </TabView>
            {this.state.showSpielbarePhaseDialog && <SpielbarePhaseDialog tournamentId={turnierKo.id} onClose={() => this.setState({showSpielbarePhaseDialog: false})} visible={this.state.showSpielbarePhaseDialog}/>}
            {this.state.showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => this.showError(message)}
                                                               onInfo={(message) => this.showInfo(message)}
                                                               onClearMessages={() => this.messages.clear()}
                                                               callBackRefresh={this.ladeTurnierDaten}
                                                               callBackCloseDialog={() => console.log("callBackCloseDialog nicht unterstützt")}
                                                               proTournament={this.state.turnierGruppe}
                                                               onClose={() => this.setState({showAktionen: false})}
                                                               callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                               callBackLoadNewTournament={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
            />}
            {this.state.showEditDialog && <TurnierVerwaltenDialog tournamentId={turnier.id}
                                                                  callBackRefresh={this.ladeTurnierDaten}
                                                                  callBackOnDelete={() => window.open(ConfigUtil.getBasicClientUrl() + NAVIGATION_ITEM.DASHBOARD.route, '_self')}
                                                                  callBackOnClose={() => this.setState({showEditDialog:false})}/>
            }
            {this.state.showEditFolgeTurnierDialog && <TurnierVerwaltenDialog tournamentId={turnierKo.id}
                                                                              callBackRefresh={this.ladeTurnierDaten}
                                                                              callBackOnDelete={() => {
                                                                                  this.setState({showEditFolgeTurnierDialog:false});
                                                                                  this.ladeTurnierDaten();
                                                                              }}
                                                                              callBackOnClose={() => this.setState({showEditFolgeTurnierDialog:false})}/>
            }
            {this.state.showEditLuckyLoserTurnierDialog && <TurnierVerwaltenDialog tournamentId={turnierLuckyLoser.id}
                                                                              callBackRefresh={this.ladeTurnierDaten}
                                                                                   callBackOnDelete={() => {
                                                                                       this.setState({showEditLuckyLoserTurnierDialog:false});
                                                                                       this.ladeTurnierDaten();
                                                                                   }}
                                                                              callBackOnClose={() => this.setState({showEditLuckyLoserTurnierDialog:false})}/>
            }
            {this.state.showGroupManuellDialog && <TournamentGroupManuellDialog tournamentId={turnier.id}
                                                                                players={this.state.turnierGruppe.tournamentPlayerEntities}
                                                                  callBackOnClose={() => this.setState({showGroupManuellDialog:false})}/>
            }
            {this.state.showAbschliessenDialog &&
                <TurnierGruppenphaseAbschliessenDialog
                    tournamentid={turnier.id}
                    oldTournamentSystem={turnier.tournamentsystem}
                    gameType={turnier.gameType}
                    callBackCloseDialog={() => this.setState({showAbschliessenDialog: false})}
                    callBackRefresh={this.ladeTurnierDaten}/>
            }
            {this.state.showRundUmsBoardDialog && <RundUmsBoardDialog eventOperatorId={turnier.eventOperatorId} onClose={() => this.setState({showRundUmsBoardDialog: false})}  />}
            <ConfirmKennwortDialog visible={this.state.showKennwortDialog}
                                   header="Turnierkennwort eingeben."
                                   textOben="Der Turnierbetreiber erwartet ein Kennwort zur Teilnahme."
                                   changeKennwort={(kennwort) => this.setState({kennwort: kennwort})}
                                   callBackOnAbbrechen={() => this.setState({showKennwortDialog:false})}
                                   callBackOnAbsenden={() => this.state.turnierGruppe.doppelturnier ? this.teilnahmeDoppelAendern() : this.teilnahmeAendern()}/>
            {this.state.showAuslosungDialog && isSchweizerSystem &&
                <TournamentSchweizerSystemAuslosungDialog onClose={() => this.setState({showAuslosungDialog: false})}
                                           onRefresh={() => this.ladeDaten()}
                                           tournament={this.state.turnierGruppe} />
            }
            {this.state.showAuslosungDialog && (isRoundRobin || gruppenturnier) &&
                <TournamentRoundRobinOrGruppenturnierAuslosungDialog  onClose={() => this.setState({showAuslosungDialog: false})}
                                                                      onRefresh={() => this.ladeDaten()}
                                                                      tournament={this.state.turnierGruppe}  />
            }
            <ConfirmWithQuestionDialog visible={this.state.showDeleteConfirmDialog}
                                       callBackOnNein={() => this.setState({
                                           showDeleteConfirmDialog: false
                                       })}
                                       callBackOnJa={() => this.teilnahmeNachTurnierstartBeenden()}
                                       header="Teilnehmer am Turnier beenden"
                                       textOben="Das Turnier läuft bereits, du solltest nicht ohne sehr wichtigen Grund aus dem Turnier austreten. Sofern du austreten möchtest, schreibe den Grund bitte noch in den Chat."
                                       textUnten="Möchtest du wirklich deine Teilnahme beenden?"/>
        </div>
    }

    getZuschauenArea() {
        return <LigaZuschauenArea tournamentId={this.state.turnierGruppe.id}/>
    }

    getChat() {
        return <Chat type="turnier" loadData={true} id={this.state.turnierGruppe.id} userId={this.props.userId}
                     callBackOnError={(message) => this.showError(message)}/>
    }

    getAdministratoren() {
        if (this.state.turnierGruppe === null || this.state.turnierGruppe === undefined || this.state.turnierGruppe.administratoren === null || this.state.turnierGruppe.administratoren === undefined) {
            return "";
        }
        let administratoren = "";
        for (const administrator of this.state.turnierGruppe.administratoren) {
            if( administratoren !== "") {
                administratoren += " - ";
            }
            administratoren += administrator.name;
        }
        return administratoren;
    }

    getTeilnehmerArea() {
        return <LigaTeilnehmerArea liga={this.state.turnierGruppe}
                                   players={this.state.turnierGruppe.tournamentPlayerEntities}
                                   showEditButton={true}
                                   readonly={this.state.readonly}
                                   getUsernamesByUserId={this.getUsernamesByUserId}
                                   callBackRefresh={this.ladeDaten}
                                   isVerschiebenErlaubt={false}
                                   showUsernames={!this.state.turnierGruppe.doppelturnier}
                                   isAdmin={this.isAktuellerUserAdministrator()}/>
    }

    getBeschreibungArea(fixedColumWidth) {
        if (this.state.turnierGruppe.beschreibung === null || this.state.turnierGruppe.beschreibung === undefined) {
            return "";
        }
        let beschreibungGekuerzt = false;
        let beschreibung;
        if (this.state.zeigeBeschreibungVoll || this.state.turnierGruppe.beschreibung.length <= 50) {
            beschreibung = this.state.turnierGruppe.beschreibung;
        } else {
            beschreibung = this.state.turnierGruppe.beschreibung.substring(0, 50);
            beschreibungGekuerzt = true;
        }
        return <div className="grid">
            <div className="col-fixed" style={{width: fixedColumWidth}}>Beschreibung:</div>
            <div className="col" style={{whiteSpace: "pre-line", cursor: "pointer"}}
                 onClick={() => this.setState({zeigeBeschreibungVoll: !this.state.zeigeBeschreibungVoll})}>
                {beschreibung}{beschreibungGekuerzt ? <span style={{color:"blue"}}><br/>... mehr Anzeigen ...</span> : ""}
            </div>
        </div>
    }

    getGamesArea() {
        if( this.state.userGames === null || this.state.userGames === undefined || this.state.userGames.length === 0) {
            return "";
        }
        let cols = [];
        for (const userGame of this.state.userGames) {
            cols.push(<div className="col" key={"key_mygame_" + userGame.userGegnerId} style={{textAlign:"left", marginLeft:20}}>
                <TournamentGameShortComponent userGame={userGame}/>
            </div>)
        }

        return <div style={{marginTop:20, padding:20}}>
                <div className="grid"><div className="col" style={{textAlign: "left"}}>Deine aktuell anstehende Spiele, bitte gehe umgehend in dein aktuelles Spiel:</div></div>
            {cols.length === 0 && <div style={{textAlign: "left"}}>Aktuell sind keine Spiele für dich vorhanden</div>}
            {cols.length !== 0 && <div className="grid" style={{marginTop:10}}>{cols}</div>}
        </div>
    }

    teilnahmeNachTurnierstartBeenden() {
        this.messages.clear();
        let tournamentid = this.state.turnierGruppe.id;
        let url = ConfigUtil.getConfig().resourceUrls.tournament + "/teilnahmeNachTurnierstartBeenden?tournamentid=" + tournamentid;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Teilnahme wurde entfernt");
                this.setState({showDeleteConfirmDialog: false});
                this.ladeDaten()
            },
            responseNotOk => {
                this.showError("Fehler beim Entfernen eines Teilnehmers: " + responseNotOk.message);
                this.setState({showDeleteConfirmDialog: false});
            },
            error => {
                this.showError("Fehler beim Entfernen eines Teilnehmers: " + error.message);
                this.setState({showDeleteConfirmDialog: false});
            }
        );
    }

    getSchweizerSystemRunden() {
        if (this.state.turnierGruppe === undefined) {
            return "";
        }
        return <SchweizerSystemRunden tournament={this.state.turnierGruppe}
                                   gruppenTabellen={this.state.gruppenTabellen}
                                   userId={this.props.userId}
                                   boardGames={this.state.boardGames}
                                   liveGames={[]}
                                   isSetModus={this.isGruppenphaseSetModus()}
                                   onRefresh={this.ladeDaten}
                                   isReadOnly={!this.isAktuellerUserAdministrator()
                                       || this.state.turnierGruppe.tournamentstatus === "abgeschlossen"}/>
    }

    getTurnierGruppen() {
        if (this.state.turnierGruppe === undefined) {
            return "";
        }
        if( this.state.turnierGruppe.tournamentAuslosungEntities.length > 0 ) {
            let gruppenButtons = [];
            for(let gruppe = 1; gruppe <= this.state.turnierGruppe.anzahlGruppen; gruppe++) {
                gruppenButtons.push(<Button className={ this.state.selectedGroup === gruppe ? "" : "p-button-secondary"} label={gruppe} style={{width:100, marginRight:5, marginTop:5}} onClick={() => this.setState({selectedGroup: gruppe})}/> );
            }
            let gruppeModelWrapperListe = this.state.gruppeModelWrapperListe;
            let modelWrapper = gruppeModelWrapperListe.filter(wrapper => wrapper.gruppe === this.state.selectedGroup)[0];
            return <div>
                {this.isAktuellerUserAdministrator() && this.state.tournamentsystem !== TOURNAMENTSYSTEMS.ROUND_ROBIN.id && <div className="grid" style={{padding: 5, paddingTop:10, marginLeft: 0, marginRight: 0}}>
                        <Button label="Gruppenphase abschliessen"
                                disabled={this.state.turnierGruppe !== undefined && this.state.turnierGruppe.folgeTurnierId !== null && this.state.turnierGruppe.folgeTurnierId !== undefined}
                                onClick={(e) => this.setState({showAbschliessenDialog: true})} style={{marginRight: 20}}/>
                    </div>
                }
                <div style={{display:"flex", flexWrap:"wrap", backgroundColor:"#022836", padding:5}}>{gruppenButtons}</div>
                <Turnierbaum userId={this.props.userId}
                             turnier={this.state.turnierGruppe}
                             tournamentGroup={this.state.selectedGroup}
                             userGames={this.state.userGames}
                             setzliste={this.state.setzliste}
                             model={modelWrapper.model}
                             readonly={!this.isAktuellerUserAdministrator() || this.state.turnierGruppe.tournamentstatus === "abgeschlossen"}
                             boardGames={this.state.boardGames}
                             tournamentBeschreibungen={modelWrapper.tournamentBeschreibungen}
                             loadData={this.ladeDaten}
                />
            </div>
        } else {
            return <Turniergruppen turnier={this.state.turnierGruppe}
                                   isSetModus={this.isGruppenphaseSetModus()}
                                   gruppenTabellen={this.state.gruppenTabellen}
                                   bestplatzierteDtos={this.state.bestplatzierteDtos}
                                   tournamentsystem={this.state.tournamentsystem}
                                   userId={this.props.userId}
                                   admin={this.isAktuellerUserAdministrator()}
                                   refresh={this.ladeDaten} boardGames={this.state.boardGames}/>
        }
    }

    getAdminGamesTab() {
        if( this.state.turnierGruppe.doppelturnier) {
            return "Wird in Doppelturnieren nicht unterstützt.";
        }
        let games = [];
        for (const meetingKeyDto of this.state.gameMeetingKeyDtos) {
            // meetingKeyDto tournamentGameId  meetingKey
            //tournamentErgebnisEntities - spieler1Id - spieler2Id -f feld
            let player1;
            let player2;
            let showMeetingKeyAllowed;
            if( this.state.turnierKO !== null && this.state.turnierKO !== undefined) {
                // KO Phase
                let ergebnis = this.state.turnierKO.tournamentErgebnisEntities.find(entity => entity.feld === meetingKeyDto.tournamentGameId);
                player1 = this.state.turnierKO.tournamentPlayerEntities.find(entity => entity.id === ergebnis.spieler1Id);
                player2 = this.state.turnierKO.tournamentPlayerEntities.find(entity => entity.id === ergebnis.spieler2Id);
                showMeetingKeyAllowed = this.hasPlayersMeetingkeyAllowed(this.state.turnierKO, player1, player2);
            } else {
                let game = this.state.turnierGruppe.tournamentGroupGameEntities.find(entity => entity.id === meetingKeyDto.tournamentGameId);
                player1 = this.state.turnierGruppe.tournamentPlayerEntities.find(entity => entity.id === game.spieler1);
                player2 = this.state.turnierGruppe.tournamentPlayerEntities.find(entity => entity.id === game.spieler2);
                showMeetingKeyAllowed = this.hasPlayersMeetingkeyAllowed(this.state.turnierGruppe, player1, player2);
            }

            let urlVideo = NAVIGATION_ITEM.GAME_VIDEO.route + "?gameid=" + meetingKeyDto.gameId + "&username=player&testhinweis=false";
            let urlGameWatch = NAVIGATION_ITEM.GAMEWATCH.route + "?gameid=" + meetingKeyDto.gameId;

            games.push(<div className="grid">
                <div className="col">
                    {this.getSpielerName(player1.userId)} : {this.getSpielerName(player2.userId)} - Meetingkey: {showMeetingKeyAllowed ?
                    <>
                        <NavLink to={urlVideo} style={{textDecoration: "none"}}>
                            <img src="/images/dart_select.png" alt="Watch Scoring" style={{cursor: "pointer", width: 30, marginLeft: 5}}/>
                        </NavLink>
                        <NavLink to={urlGameWatch} style={{textDecoration: "none"}}>
                            <img src="/images/webcam_color.png" alt="Watch Video" style={{cursor: "pointer", width: 30, marginLeft: 5}}/>
                        </NavLink>
                    </>
                    : "Anzeige nicht erlaubt!!!"}
                </div>
            </div>);
        }
        return <div className="grid" style={{padding:20, textAlign:"left"}}>
            <div className="col">
                <div className="grid" style={{marginBottom:50}}>
                    <div className="col">
                        Hier bekommen Liga- und Turnierleiter alle aktuell vorhandenen und nicht abgeschlossenen Spiele angezeigt.<br/>Hintergrund ist, dass evtl.Meetingkeys zum Beobachten von Spiele bzw. Streaming freigegeben wurden.
                    </div>
                </div>
                {games}
            </div>
        </div>
    }

    hasPlayersMeetingkeyAllowed(tournament, player1, player2) {
        if( tournament.doppelturnier) {
            return false;
        }
        if( player1 === undefined || player1 === null || player2 === undefined || player2 === null) {
            return false;
        }
        let usernameObject1 = this.getUsernamesByUserId(player1.userId);
        let usernameObject2 = this.getUsernamesByUserId(player2.userId);
        return usernameObject1.showMeetingkey && usernameObject2.showMeetingkey;
    }


}

TurnierGruppenDetail.propTypes = {
    userId: PropTypes.string.isRequired,
    turnierid: PropTypes.string.isRequired,
    leagueoperators: PropTypes.any,
};

export default TurnierGruppenDetail
