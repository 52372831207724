import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
import ConfirmDialog from "../../../general/ConfirmDialog";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

export default function BoardBelegungArea({eventOperatorId, refreshCounter}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [showHinweis, setShowHinweis] = useState(false);
    const [boards, setBoards] = useState([]);
    const [boardsMitGame, setBoardsMitGame] = useState([]);

    const [selectedDeleteBoardId, setSelectedDeleteBoardId] = useState(undefined);

    useEffect(() => {
        ladeDaten();
    }, [refreshCounter]);

    const ladeDaten = () => {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.eventoperator + "/getBoards/" + eventOperatorId,
            json => {
                setBoards(json.boards);
                setBoardsMitGame(json.boardsMitGame);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            });
    }

    const getBoardMitGame = (boardId) => {
        for (const boardGame of boardsMitGame) {
            if( boardId === boardGame.boardId) {
                return boardGame;
            }
        }
        return undefined;
    }

    const deleteBoardGame = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.eventoperator + "/deleteBoardGame/" + eventOperatorId + "/" + selectedDeleteBoardId,
            undefined,
            json => {
                setSelectedDeleteBoardId(undefined);
                setInfoMessage("Game am Board wurde gelöscht.");
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setSelectedDeleteBoardId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setSelectedDeleteBoardId(undefined);
            }
        );
    }

    const getBoardRows = () => {
        if( boards.length === 0) {
            return <div style={{marginTop:30}}>Keine Boards vorhanden.</div>;
        }
        let rows = [];
        for (const board of boards) {
            let existierentesBoardGameZurBoardId = getBoardMitGame(board.id);
            let divBezeichnung = <div style={{marginBottom:20}}>
                Bezeichnung: '{board.bezeichnung}'
                {board.beschreibungShared && <><br/>{board.beschreibungShared}</>}
            </div>;
            let divBoard = "";
            if(existierentesBoardGameZurBoardId) {
                divBoard = <div style={{marginLeft:10, marginBottom:20}}>
                    <div>Spiel: {existierentesBoardGameZurBoardId.gameDescription}
                        <Button icon="pi pi-trash" tooltip="Game am Board löschen"
                                style={{marginLeft: 10}}
                                onClick={() => {
                                    setSelectedDeleteBoardId(existierentesBoardGameZurBoardId.boardId);
                                }}/>
                    </div>
                    <div>Turnier: {existierentesBoardGameZurBoardId.tournamentBezeichnung}</div>
                    {existierentesBoardGameZurBoardId.schreiberName !== null && existierentesBoardGameZurBoardId.schreiberName !== undefined &&
                        <div>Schreiber: {existierentesBoardGameZurBoardId.schreiberName}</div>
                    }
                </div>;
            }
            rows.push(<div key={"key_board_" + board.id} style={{fontSize:16, marginBottom:20, borderBottom:"1px solid black"}}>
                {divBezeichnung}{divBoard}</div>);
        }
        return rows;
    }

    return <>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <Fieldset legend="Hinweis" toggleable collapsed={!showHinweis} onToggle={(e) => setShowHinweis(!showHinweis)} style={{marginBottom:10}}>
            <div style={{fontSize:16}}>Hier siehst du eine Übersicht mit den aktuellen Boardbelegungen.
            </div>
        </Fieldset>
        <ConfirmDialog visible={selectedDeleteBoardId !== undefined}
                       callBackOnNein={() => setSelectedDeleteBoardId(undefined)}
                       callBackOnJa={() => deleteBoardGame()}
                       header="Wirklich löschen..."
                       textOben=""
                       textUnten="Möchtest du das Spiel an diesem Board wirklich löschen?"/>
        {getBoardRows()}
    </>
}
