import DHDialog from "../../general/DHDialog";

export default function LigaSpieltagSpieleErzeugenHilfeDialog({onClose}) {

    const getArea = () => {
        return <div style={{padding: 20}}>
            Hier wird beschrieben, wie du Spieler zu Spielen zuweist oder dies von der Gastmannschaft machen lässt.<br/><br/>
            <hr/>
            <strong>Möglichkeit 1:</strong><br/><br/>
            Also Standard ist in Darthelfer das <strong>'versteckte Spieler in Games setzen'</strong> aktiviert.<br/><br/>
            Hierfür scannt der Gastvereinen einen QR Code, den du ihn als Heimmannschaft erzeugen lässt.<br/>
            Wechsle dazu im Ligaspieltag auf den <strong>Reiter 'Details'</strong> und klicke den Knopf <strong>QR Code für Gastverein.</strong><br/><br/>
            Auf dem geöffneten Dialog kann der QR Code von der Gastmannschaft gescannt werden.<br/>
            Die Gastmannschaft kann dann am Handy die Spieler erfassen/auswählen die an diesem Spieltag eingesetzt werden sollen.<br/>
            Danach kann die Gastmannschaft für die verschiedenen Phasen die Spieler in Spiele setzen. Hier kann das Setzen der Spieler in Spiele Phase für Phase nacheinander erfolgen.
            <br/><br/>
            Die Heimmannschaft erfasst ihre Spieler im Adminbreich. Auch das Setzen der Spieler in Spiele erfolgt im Adminbreich.<br/>
            Achtung! Der Spieltag muss über den Reiter 'Details' gestartet werden.<br/><br/>
            <strong>In Darthelfer wird sichergestellt, dass weder die Gastmannschaft noch die Heimmannschaft die Spielerauswahl vom Gegner sieht.</strong><br/><br/>
            Erst nachdem die Heimmannschaft die Spiele für diese Phase über den Knopf 'Spiele erzeugen' startet, sieht die Heimmannschaft die gesetzen Gastspieler.<br/><br/>
            Hinweis: Vor dem 'Spiele erzeugen' sollte man die Gastmannschaft fragen ob das Setzen der Spieler abgeschlossen ist, da nach dem Erzeugen der Spiele keine Änderungen mehr in dieser Phase
            möglich sind.<br/><br/>
            <hr/>
            <strong>Möglichkeit 2:</strong><br/><br/>
            <strong>Sollte dein Gegner oder du das versteckte Spieler in Games setzen nicht wollen, kann diese Option deaktiviert werden.</strong><br/><br/>
            Wechsle dazu wieder auf den Reiter 'Details' und klicke den Button 'Heimmannschaft verwaltet Gastdaten'.<br/>
            Danach kann ausschließlich die Heimmanschaft Spieler verwalten und diese dann in Spiele setzen.
        </div>
    }
    return <DHDialog onClose={onClose} title={"Hilfe - Spiele erzeugen"} buttons={[]} comp={getArea()}/>
}